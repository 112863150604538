import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  state: {
    sectors: [],
    osgbList: [],
    osbList: [],
    docTemplates: [],
  },
  actions,
  mutations,
  getters,
}
