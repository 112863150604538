<template>
  <b-form-checkbox v-model="isActive" class="custom-control-success" switch @change="$emit('change', isActive), $emit('input', isActive)">
    <span v-if="leftIcon" class="switch-icon-left">
      <feather-icon :icon="leftIcon" />
    </span>
    <span v-if="rightIcon" class="switch-icon-right">
      <feather-icon :icon="rightIcon" />
    </span>
  </b-form-checkbox>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    rightIcon: {
      type: String,
      default: "XIcon",
    },
    leftIcon: {
      type: String,
      default: "CheckIcon",
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  watch: {
    value(val) {
      this.isActive = val;
    },
  },
  mounted() {
    this.isActive = this.value;
  },
};
</script>

<style></style>
