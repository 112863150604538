<template>
  <app-sidebar
    id="app-sidebar"
    ref="appSidebar"
    :title="$store.getters.appSidebarTitle"
    @hide="cleanAppSidebar"
  >
    <div>
      <component
        :is="$store.getters.appSidebarContent"
        v-if="$store.getters.appSidebarContent"
      />
    </div>
  </app-sidebar>
</template>

<script>
export default {
  computed: {
    toggleAppsidebar() {
      return this.$store.getters.isAppSidebarActive
    },
  },
  watch: {
    toggleAppsidebar(val) {
      this.$refs.appSidebar[val ? 'show' : 'hide']()
    },
  },
  methods: {
    cleanAppSidebar() {
      setTimeout(() => this.$store.commit('cleanAppSidebar'), 300)
    },
  },
}
</script>

<style></style>
