export default {
  setEmployees: (state, employees) => (state.employees = employees),
  setEmployeeOptions: (state, employeeOptions) => (state.employeeOptions = employeeOptions),
  setNewEmployee: (state, employee) => {
    state.employees.data.unshift(employee);
    state.employees.total++;
  },
  setUpdatedEmployee: (state, employee) => {
    const index = state.employees.data.findIndex((e) => e._id === employee._id);
    // splice
    if (index !== -1) state.employees.data.splice(index, 1, employee);
  },
  setEmployeeArchived: (state, employee) => {
    const index = state.employees.data.findIndex((e) => e._id === employee);
    state.employees.data[index].isArchived = true;
  },
  // Support Teams
  setSupportTeams: (state, supportTeams) => (state.supportTeams = supportTeams),
  setNewSupportTeams: (state, supportTeam) => (state.supportTeams = state.supportTeams.concat(supportTeam)),
  deleteSupportTeamEmployee: (state, id) => {
    state.supportTeams = state.supportTeams.filter((item) => item._id !== id);
  },

  //Employee Files
  setCompanyEmployeeFiles: (state, companyEmployeeFiles) => (state.companyEmployeeFiles = companyEmployeeFiles),
  setNewCompanyEmployeeFile: (state, data) => {
    state.companyEmployeeFiles.data.unshift(data);
    state.companyEmployeeFiles.total++;
  },
  deleteEmployeeFile: (state, id) => {
    state.companyEmployeeFiles.data = state.companyEmployeeFiles.data.filter((item) => item._id !== id);
    state.companyEmployeeFiles.total--;
  },
};
