import graphqlRequest from "@/utils/graphqlRequest";
import axios from "@/utils/appAxios";

export default {
  getRiskStateOptions() {
    return new Promise((resolve, reject) => {
      axios
        .get("settings/riskstates")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
