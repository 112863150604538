export default {
  setCompanyWsFiles: (state, data) => (state.companyWsFiles = data),
  newCompanyWsFile: (state, item) => state.companyWsFiles.unshift(item),
  deleteCompanyWsFile: (state, item) => (state.companyWsFiles = state.companyWsFiles.filter((i) => i._id !== item)),

  setDetectionAndSuggestions: (state, data) => (state.detectionAndSuggestions = data),
  newDetectionAndSuggestion: (state, item) => {
    state.detectionAndSuggestions.data.unshift(item);
    state.detectionAndSuggestions.total++;
  },
  deleteDetectionAndSuggestion: (state, item) => {
    state.detectionAndSuggestions.data = state.detectionAndSuggestions.data.filter((i) => i._id !== item);
    state.detectionAndSuggestions.total--;
  },

  setObservations: (state, data) => (state.observations = data),
  newObservation: (state, item) => {
    state.observations.data.unshift(item);
    state.observations.total++;
  },
  deleteObservation: (state, item) => {
    state.observations.data = state.observations.data.filter((i) => i._id !== item);
    state.observations.total--;
  },

  setIncidents: (state, data) => (state.incidents = data),
  newIncident: (state, item) => {
    state.incidents.data.unshift(item);
    state.incidents.total++;
  },
  deleteIncident: (state, item) => {
    state.incidents.data = state.incidents.data.filter((i) => i._id !== item);
    state.incidents.total--;
  },

  setTrainings: (state, data) => (state.trainings = data),
  setTrainingAsStarted: (state, id) => {
    const index = state.trainings.data.findIndex((i) => i._id === id);
    state.trainings.data[index].isStarted = true;
  },
  newTraining: (state, item) => {
    state.trainings?.data.unshift(item);
    state.trainings.total++;
  },
  deleteTraining: (state, id) => {
    state.trainings.data = state.trainings.data.filter((i) => i._id !== id);
    state.trainings.total--;
  },
  setEmployeeTrainings: (state, data) => (state.employeeTrainings = data),

  setPlans: (state, data) => (state.plans = data),
  newPlan: (state, item) => {
    state.plans.data.unshift(item);
    state.plans.total++;
  },
  deletePlan: (state, item) => {
    state.plans.data = state.plans.data.filter((i) => i._id !== item);
    state.plans.total--;
  },

  setDocuments: (state, data) => (state.documents = data),
  newDocument: (state, item) => {
    state.documents.data.unshift(item);
    state.documents.total++;
  },
  deleteDocument: (state, item) => {
    state.documents.data = state.documents.data.filter((i) => i._id !== item);
    state.documents.total--;
  },
};
