<template>
  <b-card body-class="p-1" :border-variant="variant" class="text-center" :class="{ 'cursor-pointer statistic-card': hover }" @click="goTo">
    <b-avatar class="mb-1" :variant="`light-${variant}`" :size="avatarSize">
      <feather-icon v-if="icon" :icon="icon" :size="iconSize" />
      <mdi-icon v-else-if="mdiIcon" :icon="mdiIcon" :size="iconSize" />
      <mdil-icon v-else-if="mdilIcon" :icon="mdilIcon" :size="iconSize" />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder">
        {{ count }}
      </h2>
      <span>{{ title }}</span>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    mdiIcon: {
      type: String,
    },
    mdilIcon: {
      type: String,
    },
    iconSize: {
      type: String,
      default: "25",
    },
    avatarSize: {
      type: String,
      default: "45",
    },
    count: {
      type: [Number, String],
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "primary",
    },
    hover: {
      type: Boolean,
      default: false,
    },
    route: {
      type: String,
    },
  },
  methods: {
    goTo() {
      this.route && this.hover && this.$router.push(this.route);
    },
  },
};
</script>

<style>
.statistic-card {
  transition: all 0.3s ease;
}
.statistic-card:hover {
  transform: scale(1.05);
  transform: translate(0, -5px);
}
</style>
