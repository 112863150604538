export default {
  setWebCampaigns: (state, campaigns) => (state.campaigns = campaigns),
  newWebCampaign: (state, campaign) => state.campaigns.unshift(campaign),
  updateWebCampaign: (state, campaign) => {
    const index = state.campaigns.findIndex(c => c._id === campaign._id)
    if (index !== -1) {
      state.campaigns.splice(index, 1, campaign)
    }
  },
  deleteWebCampaign: (state, id) => (state.campaigns = state.campaigns.filter(c => c._id !== id)),
}
