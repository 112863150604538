export default {
  setBranches: (state, items) => (state.branches = items),
  setNewBranch: (state, branch) => {
    state.branches?.data?.unshift(branch);
    state.branches.total++;
  },
  updateBranch: (state, branch) => (state.branches.data = state.branches.data.map((item) => (item._id === branch._id ? branch : item))),
  setRiskomerBranches: (state, items) => (state.riskomerBranches = items),
  newRiskomerBranch: (state, item) => state.riskomerBranches.unshift(item),
  updateRiskomerBranch: (state, item) => (state.riskomerBranches = state.riskomerBranches.map((i) => (i._id === item._id ? item : i))),
  deleteRiskomerBranch: (state, item) => (state.riskomerBranches = state.riskomerBranches.filter((i) => i._id !== item)),
  setBranchData: (state, branchData) => (state.branchData = branchData),
  setSelectedBranch: (state, branch) => (state.selectedBranch = branch),
  selectedBranch: (state) => state.selectedBranch,

  // Assignments
  newBranchAssignment: (state, assignment) => state.branchData.assignments.push(assignment),
  updateAssignment: (state, assignment) =>
    (state.branchData.assignments = state.branchData.assignments.filter((item) => (item._id === assignment._id ? (item.physician = assignment.physician) : item))),
  deleteBranchAssignment: (state, assignment) => (state.branchData.assignments = state.branchData.assignments.filter((item) => item._id !== assignment._id)),
  // Visits
  setNewBranchVisit: (state, visit) => state.branchData.visits.push(visit),

  // Branches
  setBranchLocations: (state, items) => {
    const locations = items.filter((item) => item?.address?.location?.lat && item?.address?.location?.lng);
    state.branchLocations = locations;
  },
};
