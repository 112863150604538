export default {
  detectionAndSuggestions: (state) => state.detectionAndSuggestions,
  observations: (state) => state.observations,
  companyWsFiles: (state) => state.companyWsFiles,
  instructions: (state) => state.instructions,
  incidents: (state) => state.incidents,
  trainings: (state) => state.trainings,
  employeeTrainings: (state) => state.employeeTrainings,
  plans: (state) => state.plans,
  documents: (state) => state.documents,
};
