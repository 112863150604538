import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  state: {
    companies: [],
    archivedCompanies: [],
    companyData: null,
    selectedCompany: null,
    companyFiles: [],
    officials: [],
    officialNotes: [],
  },
  actions,
  mutations,
  getters,
}
