<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="campaignform">
      <app-input
        v-model="data.name"
        name="Kampanya Adı"
        label="Kampanya Adı:"
        placeholder="Kampanya Adı..."
        rules="required"
      />
      <app-date-input
        v-model="data.startDate"
        name="Başlangıç Tarihi"
        label="Başlangıç Tarihi:"
        placeholder="Tarih Seçiniz..."
        time
        rules="required"
      />
      <app-date-input
        v-model="data.endDate"
        name="Bitiş Tarihi"
        label="Bitiş Tarihi:"
        placeholder="Tarih Seçiniz..."
        time
        rules="required"
      />
      <app-mask-input
        v-model.number="data.percent"
        input-mask="##"
        name="İndirim"
        icon="PercentIcon"
        label="İndirim:"
        placeholder="İndirim Yüzdesi Giriniz..."
        rules="required"
      />

      <hr>
      <app-collapse
        class="p-0"
        type="border"
        accordion
      >
        <app-collapse-item
          v-for="item in collapseItems"
          :key="item.label"
          :title="item.label"
          title-size="sm"
        >
          <component
            :is="item.component"
            v-model="data[item.dataTarget]"
            :provinces="data.areas && data.areas.provinces ? data.areas.provinces : []"
          />
        </app-collapse-item>
      </app-collapse>
      <hr>
      <b-row class="align-items-center">
        <b-col cols="9">
          <app-button
            :text="updateItem ? 'Güncelle' : 'Oluştur'"
            block
            @click="updateItem ? updateCampaign() : newCampaign()"
          />
        </b-col>
        <b-col cols="3">
          <app-switch
            v-model="data.status"
            left-icon="CheckIcon"
            right-icon="XIcon"
          />
        </b-col>
      </b-row>
      <hr>
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import riskStates from './riskStates.vue'
import areas from './area/index.vue'
import sectors from './sectors.vue'
import osb from './osb.vue'
import osgb from './osgb.vue'
import paymentTypes from './paymentTypes.vue'
import employeeCount from './employeeCount.vue'

export default {
  components: { ValidationObserver, paymentTypes, areas },
  data() {
    return {
      data: {
        status: true,
        percent: null,
      },
      collapseItems: [
        { label: 'Bölgeler', component: areas, dataTarget: 'areas' },
        { label: 'Sektörler', component: sectors, dataTarget: 'sectors' },
        { label: 'Tehlike Sınıfları', component: riskStates, dataTarget: 'riskStates' },
        { label: 'Çalışan Sayısı', component: employeeCount, dataTarget: 'employeeCount' },
        { label: 'OSB veya Sanayi Sitesi', component: osb, dataTarget: 'osb' },
        { label: 'OSGB Değişim', component: osgb, dataTarget: 'osgb' },
      ],
    }
  },
  computed: {
    updateItem() {
      return this.$store.getters?.appSidebarItem
    },
  },
  mounted() {
    if (this.updateItem) {
      this.data = this.updateItem
    }
  },
  methods: {
    setData() {
      return { ...this.data, startDate: new Date(this.data.startDate), endDate: new Date(this.data.endDate) }
    },
    newCampaign() {
      this.$validate(this.$refs.campaignform, () => {
        this.$store.dispatch('newWebCampaign', this.setData())
      })
    },
    updateCampaign() {
      this.$validate(this.$refs.campaignform, () => {
        this.$store.dispatch('updateWebCampaign', this.setData())
      })
    },
  },
}
</script>
