export default [
  {
    path: "/customer-users",
    name: "customer-users",
    component: () => import("@/views/Users/Customers/CustomerList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/individual-customers",
    name: "individual-customers",
    component: () => import("@/views/Users/IndividualCustomers/list.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/administrators",
    name: "administrators",
    component: () => import("@/views/Users/Administrators/Administrators.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "admin-profile",
    component: () => import("@/views/Users/Administrators/Profile/Profile.vue"),
    meta: {
      requiresAuth: true,
      resource: "profile",
    },
  },
];
