import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

import tr from 'vee-validate/dist/locale/tr.json'
import { localize } from 'vee-validate'
import Verte from 'verte'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import './global-prototypes'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Vee validate language config

import 'verte/dist/verte.css'

localize('tr', tr)

// Composition API
Vue.use(VueCompositionAPI)
// register component globally
Vue.component('verte', Verte)

// import core styles
require('@core/scss/core.scss')

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export default app
