import { getUserData } from "@/auth/utils";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Auth/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/first-login",
    name: "first-login",
    component: () => import("@/views/Auth/FirstLogin/index.vue"),
    beforeEnter: (to, from, next) => {
      const userData = getUserData();
      userData.first_login ? next({ name: "dashboard" }) : next();
    },
    meta: {
      layout: "full",
      requiresAuth: true,
      resource: "first_login",
    },
  },
  {
    path: "/sms-check",
    name: "sms-check",
    component: () => import("@/views/Auth/SmsCheck.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
];
