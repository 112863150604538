export default [
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/Settings/index.vue"),
  },
  {
    path: "/settings/doc-templates",
    name: "doc-templates",
    component: () => import("@/views/Settings/DocTemplates/DocTemplateList.vue"),
    meta: {
      resource: "work-safety",
    },
  },
  {
    path: "/settings/role-management",
    name: "role-management",
    component: () => import("@/views/Settings/RoleManagement/RoleManagement.vue"),
  },
];
