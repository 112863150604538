import router from "@/router";
import axios from "@/utils/appAxios";
import { emitter } from "@/global-prototypes";

export default {
  getCompanyOptions(_, loading) {
    return new Promise((resolve, reject) => {
      axios
        .get("/company/list/company-options", { loading: loading ?? "table" })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  newCompany({ commit }, data) {
    axios
      .post("/company", data, { loading: "table" })
      .then((response) => {
        router.push({
          name: "company-details",
          params: { id: response.data._id, tab: 1 },
        });
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Firma başarıyla sisteme eklendi.",
        });
      })
      .catch((error) => {
        console.log(error);
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata oluştu",
        });
      });
  },
  updateCompany({ commit }, { id, data }) {
    axios
      .patch(`/company/${id}`, data, { loading: "sidebar" })
      .then((response) => {
        commit("updateCompany", response.data);
        commit("cleanAppSidebar");
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Firma başarıyla güncellendi.",
        });
      })
      .catch((error) => {
        console.log(error);
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata oluştu",
        });
      });
  },
  checkCompanyExist({ commit }, { id, next }) {
    axios
      .get(`/company/${id}`, { params: { populates: JSON.stringify(["sector", "employeeCount"]) }, loading: "table" })
      .then((result) => {
        if (!result.data) {
          next({ name: "error-404" });
          return;
        }
        commit("setSelectedCompany", result.data);
        next();
      })
      .catch((err) => {
        console.log(err);
        next({ name: "error-404" });
      });
  },
  uploadCompanyFile({ commit }, data) {
    axios
      .post("/company-file", data, { loading: "sidebar" })
      .then((response) => {
        commit("newCompanyFile", response.data);
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Dosya sisteme başarıyla yüklendi.",
        });
        commit("cleanAppSidebar");
      })
      .catch((error) => {
        console.log(error);
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
        });
      });
  },
  deleteCompanyFile({ commit }, id) {
    axios
      .delete(`/company-file/${id}`, { loading: "table" })
      .then(() => {
        commit("deleteCompanyFile", id);
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Dosya sistemden başarıyla silindi.",
        });
      })
      .catch((error) => {
        console.log(error);
        emitter.$emit("Notification", {
          variant: "success",
          title: "Hata Oluştu",
        });
      });
  },
};
