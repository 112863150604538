import axios from "@/utils/appAxios";
import { emitter } from "@/global-prototypes";

export default {
  getCustomers: ({ commit }, query) => {
    axios
      .get("/customer/list/paginated", { params: { ...query }, loading: "table" })
      .then((result) => {
        commit("setCustomers", result.data);
      })
      .catch((err) => {
        emitter.$emit("Notification", {
          title: "Hata Oluştu",
          variant: "danger",
          message: err?.data?.message ?? null,
        });
      });
  },
  newCustomer: ({ commit }, { data, actionType, callback }) => {
    axios
      .post("/customer/new", data, { loading: "sidebar" })
      .then(() => {
        callback && callback();
        commit("cleanAppSidebar");
        emitter.$emit("Notification", {
          title: "İşlem Başarılı",
          variant: "success",
          message: "Kullanıcı başarıyla sisteme eklendi.",
        });
      })
      .catch((error) => {
        emitter.$emit("Notification", {
          title: "Hata oluştu",
          variant: "danger",
          message: error?.data?.message ?? null,
        });
      });
  },
  updateCustomer: ({ commit }, { data, id, callback }) => {
    axios
      .patch(`/customer/update/${id}`, data, { loading: "sidebar" })
      .then((result) => {
        callback && callback();
        commit("cleanAppModalSidebar");
        commit("setAppModalOptions", { item: { data: result.data } });
        emitter.$emit("Notification", {
          title: "İşlem Başarılı",
          variant: "success",
          message: "Kullanıcı başarıyla güncellendi.",
        });
      })
      .catch((err) => {
        emitter.$emit("Notification", {
          title: "Hata oluştu",
          variant: "danger",
          message: err?.data?.message ?? null,
        });
      });
  },
  archiveCustomer: ({ commit }, id) => {
    axios
      .patch(`/customer/archive/${id}`, {}, { loading: "table" })
      .then(() => {
        commit("updateIsActive", id);
        emitter.$emit("Notification", {
          title: "İşlem Başarılı",
          variant: "success",
          message: "Kullanıcı başarıyla arşivlendi.",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  refreshCustomerPassword: ({ commit }, id) => {
    axios
      .patch(`/customer/refresh-password/${id}`, {}, { loading: "table" })
      .then(() => {
        emitter.$emit("Notification", {
          title: "İşlem Başarılı",
          variant: "success",
          message: "Kullanıcı şifresi başarıyla sıfırlandı.",
        });
      })
      .catch((err) => {
        console.log(err);
        emitter.$emit("Notification", {
          title: "Hata Oluştu",
          variant: "danger",
        });
      });
  },
  deleteCustomer: ({ commit }, { id, callback }) => {
    axios
      .delete(`/customer/delete/${id}`, { loading: "table" })
      .then(() => {
        emitter.$emit("Notification", { variant: "success", title: "Başarılı", message: "Kullanıcı başarıyla silindi." });
        callback && callback();
      })
      .catch((err) => {
        emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
      });
  },
};
