export default {
  // OSGB
  setOsgbList: (state, data) => (state.osgbList = data),
  setNewOsgb: (state, data) => state?.osgbList.unshift(data),
  updateOsgb: (state, data) => {
    const index = state?.osgbList.findIndex(item => item._id === data._id)
    if (index !== -1) {
      state?.osgbList.splice(index, 1, data)
    }
  },
  deleteOsgb: (state, id) => {
    state.osgbList = state.osgbList.filter(item => item._id !== id)
  },

  // OSB
  setOsbList: (state, data) => (state.osbList = data),
  setNewOsb: (state, data) => state?.osbList.unshift(data),
  updateOsb: (state, data) => {
    const index = state?.osbList.findIndex(item => item._id === data._id)
    if (index !== -1) {
      state?.osbList.splice(index, 1, data)
    }
  },
  deleteOsb: (state, id) => {
    state.osbList = state.osbList.filter(item => item._id !== id)
  },

  // Sectors
  newSector: (state, data) => state?.sectors.unshift(data),
  setSectors: (state, data) => (state.sectors = data),
  updateSector: (state, data) => {
    const index = state?.sectors.findIndex(item => item._id === data._id)
    if (index !== -1) {
      state?.sectors.splice(index, 1, data)
    }
  },
  deleteSector: (state, data) => {
    state.sectors = state.sectors.filter(item => item._id !== data)
  },
  setDocTemplates: (state, data) => (state.docTemplates = data),
  deleteDocTemplate: (state, id) => {
    state.docTemplates = state.docTemplates.filter(item => item._id !== id)
  },
  newDocTemplate: (state, data) => state?.docTemplates.unshift(data),
}
