export default {
  setNewCustomer: (state, user) => {
    state.customers?.data?.unshift(user);
    state.customers.total++;
  },
  setCustomers: (state, customers) => (state.customers = customers),
  updateCustomer: (state, customer) => {
    const index = state.customers?.data?.findIndex((c) => c._id === customer._id);
    state.customers?.data?.splice(index, 1, customer);
  },
  updateIsActive: (state, id) => {
    const index = state.customers?.data?.findIndex((c) => c._id === id);
    state.customers.data[index].isActive = !state.customers.data[index].isActive;
  },
};
