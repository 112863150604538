import Vue from "vue";

import VueSelect from "vue-select";
import "@core/scss/vue/libs/vue-select.scss";

import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";

// Inputs
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { mask } from "vue-the-mask";
import appInput from "./views/app-components/input/appInput.vue";

import appSelectInput from "./views/app-components/input/appSelectInput.vue";

import appDateInput from "./views/app-components/input/appDateInput.vue";

import appTextareaInput from "./views/app-components/input/appTextareaInput.vue";

import appFileInput from "./views/app-components/input/appFileInput.vue";

import appFileInputButton from "./views/app-components/input/appFileInputButton.vue";

import appMaskInput from "./views/app-components/input/appMaskInput.vue";

// Other Components

import appTable from "./views/app-components/tables/appTable.vue";

import searchableTable from "./views/app-components/tables/searchableTable.vue";

import DbSearchableTable from "./views/app-components/tables/DbSearchableTable.vue";

import appDetailTable from "./views/app-components/tables/appDetailTable.vue";

import appUserDetail from "./views/app-components/tables/appUserDetail.vue";

import appCountInfo from "./views/app-components/infoCards/appCountInfo.vue";

import StatisticCard from "./views/app-components/infoCards/StatisticCard.vue";

import appUserInfo from "./views/app-components/infoCards/appUserInfo.vue";

import appSidebar from "./views/app-components/modals/appSidebar.vue";

import appModal from "./views/app-components/modals/appModal.vue";

import customTabs from "./views/app-components/tabs/customTabs.vue";

import customCollapse from "./views/app-components/tabs/customCollapse.vue";

import BCustomTabs from "./views/app-components/tabs/BCustomTabs.vue";

import appOverlay from "./views/app-components/appOverlay.vue";

import mdiIcon from "./views/app-components/mdiIcon.vue";

import mdilIcon from "./views/app-components/mdilIcon.vue";

// Options
import sectors from "./views/app-components/options/sectors.vue";

import provinces from "./views/app-components/options/province.vue";

import counties from "./views/app-components/options/county.vue";

import districts from "./views/app-components/options/district.vue";

import physicianSelect from "./views/app-components/options/physicianSelect.vue";

import companySelect from "./views/app-components/options/companySelect.vue";

import riskstateSelect from "./views/app-components/options/riskstateSelect.vue";

import branchSelect from "./views/app-components/options/branchSelect.vue";

// Buttons
import appButton from "./views/app-components/button/appButton.vue";

import appDropdown from "./views/app-components/button/appDropdown.vue";

import appIconButton from "./views/app-components/button/appIconButton.vue";

import appSwitch from "./views/app-components/button/appSwitch.vue";

import appCalendar from "./views/app-components/appCalendar.vue";

Vue.component("v-select", VueSelect);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component("appInput", appInput);
Vue.component("appSelectInput", appSelectInput);
Vue.component("appDateInput", appDateInput);
Vue.component("appTextareaInput", appTextareaInput);
Vue.component("appFileInput", appFileInput);
Vue.component("appFileInputButton", appFileInputButton);
Vue.component("appMaskInput", appMaskInput);
Vue.component("appTable", appTable);
Vue.component("searchableTable", searchableTable);
Vue.component("DbSearchableTable", DbSearchableTable);
Vue.component("appDetailTable", appDetailTable);
Vue.component("appUserDetail", appUserDetail);
Vue.component("appCountInfo", appCountInfo);
Vue.component("StatisticCard", StatisticCard);
Vue.component("appUserInfo", appUserInfo);
Vue.component("appSidebar", appSidebar);
Vue.component("appModal", appModal);
Vue.component("customTabs", customTabs);
Vue.component("customCollapse", customCollapse);
Vue.component("BCustomTabs", BCustomTabs);
Vue.component("appOverlay", appOverlay);
Vue.component("AppCollapse", AppCollapse);
Vue.component("AppCollapseItem", AppCollapseItem);
Vue.component("mdiIcon", mdiIcon);
Vue.component("mdilIcon", mdilIcon);
Vue.component("sectors", sectors);
Vue.component("provinces", provinces);
Vue.component("counties", counties);
Vue.component("districts", districts);
Vue.component("physicianSelect", physicianSelect);
Vue.component("companySelect", companySelect);
Vue.component("riskstateSelect", riskstateSelect);
Vue.component("branchSelect", branchSelect);
Vue.component("appButton", appButton);
Vue.component("appDropdown", appDropdown);
Vue.component("appIconButton", appIconButton);
Vue.component("appSwitch", appSwitch);
Vue.component("appCalendar", appCalendar);
Vue.directive("mask", mask);
