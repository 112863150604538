export default {
  setAllEmployees: (state, items) => (state.allEmployees = items),
  newRiskomerEmployee: (state, item) => state.allEmployees.unshift(item),
  updateRiskomerEmployee: (state, item) => {
    const index = state.allEmployees.findIndex((i) => i._id === item._id);
    if (index !== -1) {
      state.allEmployees.splice(index, 1, item);
    }
  },
  setEmployeeFiles: (state, items) => (state.employeeFiles = items),
  newRiskomerEmployeeFile: (state, item) => {
    state.employeeFiles?.data.unshift(item);
    state.employeeFiles.total++;
  },
};
