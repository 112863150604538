import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  state: {
    user: null,
    userList: [],
    administrators: {
      data: [],
      total: 0,
    },
    smsCheck: null,
  },
  actions,
  mutations,
  getters,
};
