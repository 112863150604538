<template>
  <div style="white-space: nowrap" class="d-flex align-items-center">
    <b-avatar size="38" :variant="variant" circle-rounded>
      <feather-icon :icon="icon" size="20" />
    </b-avatar>
    <div class="ml-1">
      <h6 class="mb-0">
        {{ title }}
      </h6>
      <small>{{ alt_title }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alt_title: {
      type: String | Number,
    },
    title: {
      type: String,
    },
    icon: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "light-primary",
    },
  },
};
</script>

<style></style>
