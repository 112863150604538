<template>
  <app-modal
    id="app-modal"
    ref="appModal"
    :title="$store.getters.appModalTitle"
    :size="$store.getters.appModalSize"
    :no-fade="$store.getters.appModalNoFade"
    :no-header="$store.getters.appModalNoHeader"
    :no-body-gap="$store.getters.appModalNoBodyGap"
    :centered="$store.getters.appModalCentered"
    @hide="cleanAppModal"
  >
    <component :is="$store.getters.appModalContent" v-if="$store.getters.appModalContent" />
    <app-sidebar id="modal-sidebar" ref="modalSidebar" :title="$store.getters.appModalSidebarTitle" @hide="cleanAppModalSidebar">
      <component :is="$store.getters.appModalSidebarContent" v-if="$store.getters.appModalSidebarContent" />
    </app-sidebar>
  </app-modal>
</template>

<script>
export default {
  computed: {
    toggleAppModal() {
      return this.$store.getters.isAppModalActive;
    },
    toggleAppModalSidebar() {
      return this.$store.getters.isAppModalSidebarActive;
    },
  },
  watch: {
    toggleAppModal(val) {
      this.$refs.appModal && this.$refs.appModal[val ? "show" : "hide"]();
    },
    toggleAppModalSidebar(val) {
      this.$refs.modalSidebar && this.$refs.modalSidebar[val ? "show" : "hide"]();
    },
  },
  methods: {
    cleanAppModal() {
      setTimeout(() => this.$store.commit("cleanAppModal"), 100);
    },
    cleanAppModalSidebar() {
      setTimeout(() => this.$store.commit("cleanAppModalSidebar"), 100);
    },
  },
};
</script>

<style></style>
