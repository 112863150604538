import store from "@/store";

const financeRoutes = [
  { route: "contacts", resource: "finance-contacts" },
  { route: "invoice-payments", resource: "finance-payments" },
  { route: "invoices", resource: "finance-invoices" },
  { route: "expenditures", resource: "finance-expenditures" },
  /* { route: "calls", resource: "finance-calls" }, */
  { route: "sms" },
  { route: "reports", resource: "finance-reports" },
].map((item, index) => ({
  path: `/finance/${item?.route}`,
  name: `finance-${item?.route}`,
  props: true,
  component: () => import("@/views/Finance/FinanceTabs.vue"),
  props: { tabIndex: index },
  meta: {
    resource: item?.resource,
  },
}));

export default [
  ...financeRoutes,
  {
    path: `/finance/contacts/:id`,
    name: `finance-contact-detail`,
    props: true,
    beforeEnter: (to, _, next) => {
      if (!to?.params?.id) return next({ name: "error-404" });
      store.dispatch("getParasutContact", {
        id: to?.params?.id,
        callback: (result) => {
          result ? next() : next({ name: "error-404" });
        },
      });
    },
    component: () => import("@/views/Finance/contacts/ContactDetails/ContactDetails.vue"),
    meta: {
      resource: "finance-contacts",
      navActiveLink: "finance-contacts",
    },
  },
];
