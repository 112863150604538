<template>
  <b-button :href="href" :target="target" :variant="variant" :class="'p-50 btn-icon rounded-circle'" @click="$emit('click')">
    <feather-icon :icon="icon" :size="size" />
  </b-button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "PlusIcon",
    },
    size: {
      type: String,
      default: "15",
    },
    variant: {
      type: String,
      default: "gradient-secondary",
    },
    href: {
      type: String,
      default: "",
    },
    target: {
      type: String,
      default: "_self",
    },
  },
};
</script>

<style></style>
