<template>
  <b-overlay :variant="$store.state.appConfig.layout.skin" :show="show" spinner-variant="danger" blur="0" opacity="0.70" rounded="sm">
    <slot />
  </b-overlay>
</template>

<script>
export default {
  props: {
    loadingVariable: {
      type: String,
      default: "loadingOverlay",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show() {
      return this.loading ? this.loading : this.$store.getters[this.loadingVariable];
    },
  },
};
</script>

<style></style>
