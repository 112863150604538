export default {
  setCompanies: (state, companies) => (state.companies = companies),
  setCompanyData: (state, data) => (state.companyData = data),
  newCompany: (state, company) => state.companies.push(company),
  updateCompany: (state, company) => (state.selectedCompany = company),

  setCompanyFiles: (state, files) => (state.companyFiles = files),
  newCompanyFile: (state, item) => {
    state?.companyFiles?.data?.unshift(item);
    state.companyFiles.total++;
  },
  deleteCompanyFile: (state, id) => (state.companyFiles = state?.companyFiles?.filter((item) => item._id !== id)),

  setSelectedCompany: (state, company) => (state.selectedCompany = company),

  setOfficials: (state, officials) => (state.officials = officials),
  newOfficial: (state, official) => state.officials.unshift(official),
  updateOfficial: (state, official) => {
    const index = state.officials.findIndex((item) => item?._id === official?._id);
    if (index !== -1) {
      state.officials.splice(index, 1, official);
    }
  },
  deleteOfficial: (state, id) => (state.officials = state.officials.filter((item) => item._id !== id)),

  setOfficialNotes: (state, notes) => (state.officialNotes = notes),
  newOfficialNote: (state, note) => state.officialNotes.unshift(note),

  setArchivedCompanies: (state, companies) => (state.archivedCompanies = companies),
  newArchivedCompany: (state, company) => {
    const item = state.companies.find((item) => item._id === company._id);
    if (item) state.archivedCompanies.unshift(item);
    state.companies = state.companies.filter((item) => item._id !== company._id);
  },
  updateArchived: (state) => {
    state.selectedCompany.isArchived = !state.selectedCompany.isArchived;
  },
};
