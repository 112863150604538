<template>
  <div>
    <validation-provider #default="{ errors }" :name="name" :vid="id" :rules="rules">
      <b-form-group :class="{ 'm-0': noGap }" :label-for="name" :label="label" :state="errors.length > 0 ? false : null">
        <!-- Phone Input -->
        <b-form-input
          v-if="type === 'phone'"
          :id="name"
          v-model="phoneinput"
          v-mask="['0### ### ####']"
          :placeholder="placeholder"
          :state="errors.length > 0 ? false : null"
          @input="$emit('input', phoneinput)"
          @keydown.delete="phoneCheck"
        />
        <!-- Password Input -->
        <b-input-group v-else-if="type === 'password' || type === 'passwordCheck'" class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
          <b-form-input
            :id="id"
            v-model="textinput"
            :type="passwordFieldType"
            class="form-control-merge"
            :state="errors.length > 0 ? false : null"
            name="login-password"
            :placeholder="placeholder"
            @input="$emit('input', textinput)"
          />
          <b-input-group-append is-text>
            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
          </b-input-group-append>
        </b-input-group>
        <!-- Text Input (With Icon, Email, Number) -->
        <b-input-group v-else class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
          <b-input-group-prepend v-if="icon || texticon" is-text>
            <feather-icon v-if="icon" :icon="icon" /> <span v-if="texticon">{{ texticon }}</span>
          </b-input-group-prepend>
          <b-form-input
            :id="name"
            ref="textinput"
            v-model="textinput"
            :type="type"
            :state="errors.length > 0 ? false : null"
            :placeholder="placeholder"
            :disabled="disabled"
            block
            @input="$emit('input', textinput)"
            @blur="$emit('blur', $event)"
          />
          <b-input-group-append v-if="rightIcon || rightTextIcon" is-text>
            <feather-icon v-if="rightIcon" :icon="rightIcon" /> <span v-if="rightTextIcon">{{ rightTextIcon }}</span>
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { required, email, confirmed, url, min, max, min_value, max_value } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    icon: {
      type: String,
    },
    rightIcon: {
      type: String,
    },
    maxInput: {
      type: String | Number,
      default: "",
    },
    noGap: {
      type: Boolean,
      default: false,
    },
    texticon: {
      type: String,
    },
    rightTextIcon: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
    },
    value: {
      type: String | Number,
    },
  },
  data() {
    return {
      required,
      email,
      confirmed,
      url,
      min,
      max,
      min_value,
      max_value,
      textinput: null,
      phoneinput: "0",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  watch: {
    value(item) {
      this.type === "phone" ? (this.phoneinput = item) : (this.textinput = item);
    },
  },
  mounted() {
    this.valueCheck();
  },
  methods: {
    phoneCheck(event) {
      if (this.phoneinput.length < 2) event.preventDefault();
    },
    valueCheck() {
      this.type === "phone" ? (this.phoneinput = this.value) : (this.textinput = this.value);
    },
    focus() {
      this.$refs.textinput.focus();
    },
  },
};
</script>
