<template>
  <app-select-input
    v-model="branch"
    :label="disableLabel ? '' : 'Sicil:'"
    name="Sicil"
    :selectable="selectable"
    :options="options"
    select_label="name"
    placeholder="Sicil Seçiniz..."
    :reduce="reduce"
    :rules="required ? 'required' : ''"
    :no-gap="noGap"
    @input="sendData"
    :loading="loading"
  >
    <template #selected-option-container="data">
      <div class="vs__selected">
        {{ $fn(data.option.name, 15) }}
      </div>
    </template>
  </app-select-input>
</template>

<script>
export default {
  props: {
    value: {
      type: String | Object,
    },
    reduce: {
      type: Function,
    },
    company: {
      type: String,
      default: null,
    },
    selectable: {
      type: Function,
    },
    updateStore: {
      type: Boolean,
      default: false,
    },
    noGap: {
      type: Boolean,
      default: false,
    },
    disableLabel: {
      type: Boolean,
      default: false,
    },
    selectIfSingleElementExist: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      branch: null,
      options: [],
      loading: false,
    }
  },
  watch: {
    value(item) {
      this.branch = item
    },
    company() {
      this.getBranchOptions()
    },
  },
  mounted() {
    this.getBranchOptions()
    if (this.value) {
      this.branch = this.value
      this.getBranchOptions()
    }
  },
  methods: {
    getBranchOptions() {
      this.branch = null
      this.$emit('input', null)
      if (this.company) {
        this.loading = true
        this.$store
          .dispatch('getBranchOptions', this.company)
          .then(options => {
            this.options = options
            this.loading = false
            if (this.selectIfSingleElementExist && options.length === 1) {
              this.branch = options[0]?._id
              this.updateStore && this.$store.commit('setSelectedBranch', this.branch)
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else this.options = []
    },
    sendData() {
      this.$emit('input', this.branch)
      if (this.updateStore) this.$store.commit('setSelectedBranch', this.branch)
    },
  },
}
</script>

<style></style>
