import store from "@/store";

export default [
  {
    path: "/companies",
    name: "companies",
    component: () => import("@/views/Companies/list/CompanyTabs.vue"),
    meta: {
      requiresAuth: true,
      resource: "work-safety",
      action: "read",
    },
  },
  {
    path: "/companies/new",
    name: "new-company",
    component: () => import("@/views/Companies/companyForm/create/index.vue"),
    meta: {
      requiresAuth: true,
      navActiveLink: "companies",
      resource: "work-safety",
      action: "read",
    },
  },
  {
    path: "/companies/:id",
    name: "company-details",
    component: () => import("@/views/Companies/details/index.vue"),
    props: true,
    beforeEnter: (to, _, next) => {
      store.dispatch("checkCompanyExist", { id: to.params.id, next });
    },
    meta: {
      requiresAuth: true,
      navActiveLink: "companies",
      resource: "work-safety",
      action: "read",
    },
  },
];
