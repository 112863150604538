import axios from '@/utils/appAxios'
import { emitter } from '@/global-prototypes'

export default {
  getCampaignAnnounces: ({ commit }) => {
    axios
      .get('/campaign-announces/list', { loading: 'table' })
      .then(response => {
        commit('setCampaignAnnounces', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  newCampaignAnnounce({ commit }, data) {
    axios
      .post('/campaign-announces', data, { loading: 'sidebar' })
      .then(response => {
        commit('newCampaignAnnounce', response.data)
        commit('cleanAppSidebar')
        emitter.$emit('Notification', {
          variant: 'success',
          title: 'Kampanya Duyurusu Eklendi',
        })
      })
      .catch(err => {
        emitter.$emit('Notification', {
          variant: 'danger',
          title: 'Hata oluştu',
          message: err?.response?.data?.message,
        })
      })
  },
  updateAnnounce({ commit }, { id, data }) {
    axios
      .patch(`/campaign-announces/${id}`, data, { loading: 'sidebar' })
      .then(response => {
        commit('updateAnnounce', response.data)
        commit('cleanAppSidebar')
        emitter.$emit('Notification', {
          variant: 'success',
          title: 'Kampanya Duyurusu Güncellendi',
        })
      })
      .catch(error => {
        console.log(error)
        emitter.$emit('Notification', {
          variant: 'danger',
          title: 'Hata oluştu',
        })
      })
  },
  deleteAnnounce({ commit }, id) {
    axios
      .delete(`/campaign-announces/${id}`)
      .then(response => {
        commit('deleteAnnounce', response.data._id)
        emitter.$emit('Notification', {
          variant: 'success',
          title: 'Kampanya Duyurusu Silindi',
        })
      })
      .catch(error => {
        console.log(error)
        emitter.$emit('Notification', {
          variant: 'danger',
          title: 'Hata oluştu',
        })
      })
  },
}
