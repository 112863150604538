import appAxios from '@/utils/appAxios'

export default {
  getPendingCallRequests: ({ commit }) => {
    appAxios
      .get('/central/web-call-request-list/pending', { loading: 'table' })
      .then(result => {
        commit('setPendingCallRequests', result.data)
      })
      .catch(err => {
        console.log(err)
      })
  },
  getRequestHistory: ({ commit }) => {
    appAxios
      .get('/central/web-call-request-list/history', { loading: 'table' })
      .then(result => {
        commit('setRequestHistory', result.data)
      })
      .catch(err => {
        console.log(err)
      })
  },
}
