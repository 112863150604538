<template>
  <div>
    <app-select-input
      v-model="county"
      :options="options"
      name="İlçe"
      label="İlçe:"
      placeholder="İlçe Seçiniz..."
      select_label="value"
      :rules="required ? 'required' : ''"
      :reduce="reduce"
      :loading="loading"
      @input="$emit('input', county), $emit('selected', county)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String | Object,
    },
    required: { type: Boolean, default: false },
    reduce: { type: Function },
    province: { type: Object | String },
  },
  data() {
    return {
      county: null,
      options: [],
      loading: false,
    };
  },
  watch: {
    province(item) {
      if (item) this.setCounties(item?._id ?? item);
      else {
        this.county = null;
        this.options = [];
        this.$emit("input", null);
      }
    },
    value(item) {
      this.county = item;
    },
  },
  methods: {
    setCounties(province) {
      if (province) {
        this.loading = true;
        this.$axios
          .get(`/option-data/county?province=${province}`)
          .then((response) => {
            if (this?.county?.province !== province) {
              this.$emit("input", null);
              this.county = null;
            }
            this.loading = false;
            this.options = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$emit("input", null);
      }
    },
  },
};
</script>

<style></style>
