import { emitter } from "@/global-prototypes";
import axios from "@/utils/appAxios";

export default {
  getAllRiskomerEmployees: ({ commit }) => {
    axios
      .get("/riskomer-employees", { loading: "table" })
      .then((res) => {
        commit("setAllEmployees", res.data /* .map((item) => ({ ...item, _rowVariant: !item?.auth?.allowed ? "secondary" : "" })) */);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getRiskomerPhysicians: ({ commit }, role) => {
    axios
      .get("riskomer-employees/physicians", { params: { role }, loading: "table" })
      .then((res) => {
        commit(
          "setAllEmployees",
          res.data.map((item) => ({ ...item, _rowVariant: !item?.auth?.allowed ? "" : "" }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },
  newRiskomerEmployee: ({ commit }, data) => {
    axios
      .post("/riskomer-employees", data, { loading: "sidebar" })
      .then((res) => {
        emitter.$emit("Notification", {
          variant: "success",
          title: "Çalışan başarıyla eklendi",
        });
        commit("newRiskomerEmployee", res.data);
        commit("cleanAppSidebar");
      })
      .catch((err) => {
        console.log(err);
      });
  },
  updateRiskomerEmployee: ({ commit }, { id, data }) => {
    axios
      .patch(`/riskomer-employees/update/${id}`, data, { loading: "sidebar" })
      .then((res) => {
        emitter.$emit("Notification", {
          variant: "success",
          title: "Çalışan başarıyla güncellendi",
        });
        commit("updateRiskomerEmployee", res.data);
        commit("setAppModalOptions", { item: res.data });
        commit("cleanAppModalSidebar");
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
