<template>
  <app-select-input
    v-model="physician"
    :label="$physicianRole(role) + ':'"
    :reduce="(option) => option._id"
    :name="$physicianRole(role)"
    :selectable="(option) => (disableOption ? option._id !== disableOption : option)"
    :options="options"
    select_label="auth.fullname"
    :placeholder="$physicianRole(role) + ' Seçiniz...'"
    :multiple="multiple"
    :rules="required ? 'required' : ''"
    :loading="loading"
    @input="$emit('input', physician)"
  />
</template>

<script>
export default {
  props: {
    role: {
      type: String,
      default: "physician",
    },
    disableOption: {
      type: String,
    },
    value: {
      type: String | Object,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      physician: null,
      options: [],
      loading: false,
    };
  },
  watch: {
    role(val) {
      if (val) {
        this.physician = null;
        this.$emit("input", null);
        this.getPhysicianOptions(val);
      }
    },
    value(item) {
      this.physician = item;
    },
  },
  mounted() {
    this.physician = this.value;
    this.getPhysicianOptions();
  },
  methods: {
    getPhysicianOptions() {
      this.loading = true;
      this.$store
        .dispatch("getPhysicianOptions", this.role)
        .then((options) => {
          this.options = options;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
