import axios from "@/utils/appAxios";
import { emitter } from "@/global-prototypes";

export default {
  getPhysicianOptions: (_, roleKey) =>
    new Promise((resolve, reject) => {
      axios
        .get("/physicians/options", { params: { roleKey } })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  updatePhysician: ({ commit }, { id, data }) => {
    axios
      .patch(`/update-user/${id}`, data, { loading: "sidebar" })
      .then((res) => {
        commit("cleanAppSidebar");
        commit("updatePhysician", res.data);
        emitter.$emit("Notification", {
          title: "İşlem Başarılı",
          variant: "success",
          message: "Kullanıcı başarıyla güncellendi.",
        });
      })
      .catch((err) => {
        console.log(err.status);
        emitter.$emit("Notification", {
          title: "Hata oluştu",
          message: err.status === 409 ? "E-Posta adresi başka bir kullanıcı tarafından kullanılmaktadır." : "",
          variant: "danger",
        });
      });
  },
};
