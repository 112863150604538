<template>
  <div>
    <app-select-input
      v-model="district"
      :options="options"
      name="Mahalle"
      label="Mahalle:"
      placeholder="Mahalle Seçiniz..."
      select_label="value"
      :rules="required ? 'required' : ''"
      :reduce="reduce"
      :loading="loading"
      @input="$emit('input', $event), $emit('selected', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String | Object,
    },
    required: { type: Boolean, default: false },
    reduce: { type: Function },
    county: { type: Object | String },
  },
  data() {
    return {
      district: null,
      options: [],
      loading: false,
    };
  },
  watch: {
    county(item) {
      if (item) {
        this.setDistricts(item._id);
      } else {
        this.options = [];
        this.$emit("input", null);
      }
    },
    value(item) {
      this.district = item;
    },
  },
  mounted() {
    this.setDistricts(this?.county?._id);
    this.district = this.value;
  },
  methods: {
    setDistricts(county) {
      this.district = null;
      if (county) {
        this.loading = true;
        this.$axios
          .get(`option-data/district?county=${county}`)
          .then((response) => {
            this.options = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      } else this.$emit("input", null);
    },
  },
};
</script>

<style></style>
