<template>
  <sectors
    v-model="sectors"
    :reduce="(option) => option._id"
    multiple
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: { value: Array },
  data() {
    return {
      sectors: [],
    }
  },
  watch: {
    value() {
      this.sectors = this.value
    },
  },
  mounted() {
    if (this.value) this.sector = this.value
  },
}
</script>

<style></style>
