import axios from "axios";

const host = window.location.hostname;

const token = sessionStorage.getItem("token");
axios.defaults.headers.common.Authorization = token;

export const baseURL = host === "admin.riskomer.com" ? process.env.VUE_APP_SERVER_URL : `http://${host}:2223`;

export default axios.create({
  baseURL,
});
