import { emitter } from "@/global-prototypes";
import appAxios from "@/utils/appAxios";
import graphqlRequest from "@/utils/graphqlRequest";

export default {
  newAssignment: ({ commit }, { data, callback }) => {
    appAxios
      .post("/rest-assignments/create", data, { loading: "sidebar" })
      .then((result) => {
        callback && callback();
        commit("cleanAppSidebar");
        commit("cleanAppModalSidebar");
        emitter.$emit("Notification", {
          title: "İşlem Başarılı",
          variant: "success",
          message: "Atama başarıyla gerçekleştirildi",
        });
      })
      .catch((err) => {
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
          message: err?.data?.message ?? null,
        });
      });
  },
  deactiveAssignment: ({ commit }, { id, callback }) => {
    appAxios
      .patch(`/rest-assignments/deactive-assignment/${id}`, {}, { loading: "table" })
      .then((response) => {
        callback && callback();
        emitter.$emit("Notification", {
          title: "İşlem Başarılı",
          variant: "success",
          message: "Atama başarıyla kaldırıldı",
        });
      })
      .catch((err) => {
        console.log(err);
        emitter.$emit("Notification", {
          title: "Hata Oluştu",
          variant: "danger",
        });
      });
  },
};
