<template>
  <div>
    <validation-provider #default="{ errors }" :name="name" :rules="rules">
      <b-form-group :class="{ 'm-0': noGap }" :label-for="name" :label="label" :state="errors.length > 0 ? false : null">
        <v-select
          v-model="selectinput"
          :reduce="reduce"
          class="v-text-field"
          :options="options"
          :label="selectLabel"
          :placeholder="placeholder"
          :multiple="multiple"
          :close-on-select="!multiple"
          :deselect-from-dropdown="multiple"
          :disabled="disabled"
          :selectable="selectable"
          :filter="filter"
          :filter-by="filterBy"
          :loading="loading"
          @input="$emit('input', selectinput)"
          @search:blur="$emit('search:blur')"
          :append-to-body="appendToBody"
          :filterable="!searchable"
          @search="onSearch"
          :tabindex="5"
          size="sm"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="mr-50 mb-25"><feather-icon icon="ChevronDownIcon" size="18" /></span>
          </template>
          <template #no-options> <small v-if="searchable"> Lütfen arama yapın... </small> <small v-else>Sonuç Bulunamadı.</small> </template>
          <template #list-header>
            <slot name="list-header" />
          </template>
          <template #option="data">
            <slot v-if="$scopedSlots['option']" name="option" v-bind="data" />
            <span v-else>
              {{ capitalize ? $capitalize(showValue(data)) : showValue(data) }}
            </span>
          </template>
          <template #selected-option-container="data">
            <slot v-if="$scopedSlots['selected-option-container']" name="selected-option-container" v-bind="data" />
            <div v-else class="vs__selected">
              {{ capitalize ? $capitalize(showValue(data)) : showValue(data) }}
            </div>
          </template>
          <template #selected-option="data">
            <slot v-if="$scopedSlots['selected-option']" name="selected-option" v-bind="data" />
            <div v-else class="vs__selected">
              {{ capitalize ? $capitalize(showValue(data)) : showValue(data) }}
            </div>
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    options: {
      type: Array,
    },
    rules: {
      type: String,
      default: "",
    },
    label: {
      type: String,
    },
    select_label: {
      type: String,
      default: "value",
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    reduce: {
      type: Function,
    },
    filter: {
      type: Function,
    },
    /* filterBy: {
      type: Function,
      default: function (option, label, search) {
        if (this.select_label.includes(".")) {
          console.log(eval("option?.", this.select_label));
        }
        return label?.toLocaleLowerCase("tr")?.indexOf(search?.toLocaleLowerCase("tr")) > -1;
      },
    }, */
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Function,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object | String,
    },
    noGap: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectinput: null,
      required,
      typingTimeout: null,
    };
  },
  computed: {
    selectLabel() {
      return this.select_label.includes(".") ? this.select_label.substring(0, this.select_label.indexOf(".")) : this.select_label;
    },
    showValue() {
      return (data) => {
        const field = data.option ? "data?.option?." : "data?.";
        return eval(field + this.select_label.replaceAll(".", "?."));
      };
    },
  },
  methods: {
    onSearch(search, loading) {
      if (this.searchable) {
        clearTimeout(this.typingTimeout);
        this.typingTimeout = setTimeout(() => {
          this.searchable && this.$emit("search", search, loading);
        }, 500);
      }
    },
    filterBy(option, label, search) {
      return this.select_label.includes(".")
        ? eval("option?." + this.select_label)
            ?.toLocaleLowerCase("tr")
            ?.indexOf(search?.toLocaleLowerCase("tr")) > -1
        : label?.toLocaleLowerCase("tr")?.indexOf(search?.toLocaleLowerCase("tr")) > -1;
    },
  },
  watch: {
    value(item) {
      this.selectinput = item;
    },
  },
  mounted() {
    this.selectinput = this.value;
  },
};
</script>

<style>
.v-text-field input::placeholder {
  opacity: 0.6;
}
.vs__dropdown-menu {
  z-index: 999999999;
}
</style>
