<template>
  <div>
    <provinces
      v-model="areas.provinces"
      :reduce="(option) => option._id"
      multiple
      @search:blur="dropdownClosed"
    />
    <counties
      v-show="areas.provinces && areas.provinces.length > 0"
      ref="counties"
      v-model="areas.counties"
    />
  </div>
</template>

<script>
import counties from './counties.vue'

export default {
  components: { counties },
  props: { value: Object },
  data() {
    return {
      areas: {
        provinces: [],
        counties: [],
      },
      oldProvinces: [],
    }
  },
  watch: {
    areas: {
      deep: true,
      handler(items) {
        this.$emit('input', items)
      },
    },
    value() {
      this.areas = this.value
      if (this?.areas?.provinces?.length > 0) {
        this.$refs.counties.getCounties(this.areas.provinces)
        this.oldProvinces = this.areas.provinces
      }
    },
  },
  mounted() {
    if (this.value) this.areas = this.value
    if (this?.areas?.provinces?.length > 0) {
      this.$refs.counties.getCounties(this.areas.provinces)
      this.oldProvinces = this.areas.provinces
    }
  },
  methods: {
    dropdownClosed() {
      if (JSON.stringify(this.oldProvinces) !== JSON.stringify(this.areas.provinces) && this.areas.provinces.length > 0) {
        this.$refs.counties.cleanOptions()
        this.$refs.counties.getCounties(this.areas.provinces)
        this.oldProvinces = this.areas.provinces
      }
    },
  },
}
</script>

<style></style>
