<template>
  <validation-provider
    #default="{ errors }"
    :name="name"
    :rules="rules"
  >
    <b-form-group
      :label-for="name"
      :label="label"
    >
      <b-textarea
        v-model="input"
        type="text"
        :placeholder="placeholder"
        :state="errors.length > 0 ? false : null"
        :rows="rows"
        @input="$emit('input', input)"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required, max } from '@validations'

export default {
  components: {
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    rows: {
      type: String | Number,
    },
    rules: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      input: null,
      required,
      max,
    }
  },
  watch: {
    value(item) {
      this.input = item
    },
  },
  mounted() {
    this.input = this.value
  },
}
</script>

<style></style>
