import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  state: {
    assignments: [],
    wsExpertAssignments: [],
    physicianAssignments: [],
  },
  actions,
  mutations,
  getters,
};
