<template>
  <validation-provider #default="{ errors }" :vid="id" :name="name" :rules="rules">
    <b-form-group :label-for="name">
      <label v-if="label" :class="labelSize ? `fs-${labelSize}` : ''">{{ label }}</label>
      <b-input-group :class="{ 'is-invalid': errors.length > 0 }">
        <flat-pickr id="dateinput" ref="dateinput" v-model="date" :config="configs" :placeholder="placeholder" @input="$emit('input', date)" />
        <b-input-group-append v-if="date" is-text>
          <feather-icon class="cursor-pointer" icon="XIcon" @click="cleanInput" />
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger">{{ checkError(errors) }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import "flatpickr/dist/plugins/confirmDate/confirmDate.css";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    flatPickr,
    ValidationProvider,
  },
  props: {
    time: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String | Number,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    mode: {
      type: String,
      default: "single",
    },
    minDate: {
      type: Date | Number,
    },
    maxDate: {
      type: Date | Number,
    },
    labelSize: {
      type: String | Number,
    },
    rules: {
      type: String,
    },
    value: {
      type: String | Date,
    },
  },
  data() {
    return {
      date: null,
    };
  },
  computed: {
    configs() {
      return {
        wrap: true,
        plugins: [ConfirmDatePlugin()],
        enableTime: this.time,
        locale: Turkish,
        altInput: true,
        altFormat: this.time ? "j F Y H:i" : "j F Y",
        altInputClass: "form-control",
        minDate: this.minDate,
        maxDate: this.maxDate,
        mode: this.mode,
      };
    },
    checkError() {
      return (errors) => {
        if (errors && errors.length > 0) {
          this.$nextTick(() => {
            this.$refs.dateinput.$el.nextSibling.className = "form-control is-invalid date-input-radius";
          });
          return errors[0];
        }
        this.$nextTick(() => {
          this.$refs.dateinput.$el.nextSibling.className = `form-control date-input-radius ${this.date && "date-input"}`;
        });
        return null;
      };
    },
  },
  watch: {
    value(item) {
      this.date = item;
    },
  },
  mounted() {
    this.date = this.value;
  },
  methods: {
    cleanInput() {
      this.date = null;
      this.$emit("input", null);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.date-input {
  border-right: 0 !important;
}
.date-input-radius {
  border-bottom-left-radius: 0.3rem !important;
  border-top-left-radius: 0.3rem !important;
}
</style>
