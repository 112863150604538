<template>
  <app-collapse type="border" :class="{ 'border rounded': border }" class="p-0" accordion :lazy="lazy">
    <app-collapse-item
      v-for="(item, key) in collapses"
      :key="key"
      :title="item.title"
      :lazy="item.lazy ? item.lazy : lazyOnce ? !mountedCollapses.includes(key) : false"
      :title-size="titleSize"
      :body-class="item.bodyClass"
      @click="checkLazy(key)"
    >
      <slot v-if="!item.component" :name="item.key || key" />
      <component :is="item.component" v-else />
    </app-collapse-item>
  </app-collapse>
</template>

<script>
export default {
  props: {
    collapses: {
      type: Array,
      default: () => [],
    },
    titleSize: {
      type: String,
    },
    border: {
      type: Boolean,
      default: false,
    },
    lazyOnce: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mountedCollapses: [],
    };
  },
  methods: {
    checkLazy(key) {
      if (this.lazyOnce) {
        if (!this.mountedCollapses.includes(key)) {
          this.mountedCollapses.push(key);
        }
      }
    },
  },
};
</script>

<style></style>
