import axios from './appAxios'

export default (route, { query, variables }, actions) => new Promise((resolve, reject) => {
  axios
    .post(route, { query, variables }, { loading: actions?.loading })
    .then(response => {
      if (response.data.errors) reject(response.data.errors[0])
      else resolve(response.data.data)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
