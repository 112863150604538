<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div v-if="showLeftSidebar" :class="{ show: isSidebarActive }" class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column">
        <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
          <div class="p-2">
            <slot name="sidebar" />
          </div>
          <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
        </div>
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar ref="refCalendar" :options="{ ...calendarOptions, editable, events: handleData }" class="full-calendar">
              <template #eventContent="data">
                <div v-b-tooltip.hover.bottom="data.event.title" class="overflow-hidden">
                  {{ data.event.title }}
                </div>
              </template>
            </full-calendar>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showLeftSidebar" class="body-content-overlay" :class="{ show: isSidebarActive }" @click="isSidebarActive = false" />
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import trlocale from "@fullcalendar/core/locales/tr";

export default {
  components: {
    FullCalendar,
  },
  props: {
    data: { type: Array, default: () => [] },
    editable: { type: Boolean, default: false },
    showLeftSidebar: { type: Boolean, default: false },
  },
  data() {
    return {
      isSidebarActive: false,
    };
  },
  computed: {
    isVerticalMenuCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed;
    },
    calendarOptions() {
      return {
        // Configuraiton
        plugins: [dayGridPlugin, interactionPlugin, listPlugin],
        initialView: "dayGridMonth",
        locale: trlocale,
        headerToolbar: {
          start: `${this.showLeftSidebar ? "sidebarToggle," : ""} prev,next, title`,
          end: "dayGridMonth,listMonth",
        },
        // Settings
        eventResizableFromStart: false,
        handleWindowResize: true,
        navLinks: true,
        dayMaxEvents: 3,
        displayEventTime: false,
        datesSet: this.datesSet,
        // Events
        eventClick: this.handleEventClick,
        eventDrop: this.eventDrop,
        eventDidMount: this.eventDidMount,
        eventRender: this.handleDatesRender,
        /* eventClassNames(args) {
          return [`bg-light-danger`];
        }, */
        customButtons: {
          sidebarToggle: {
            click: this.showSidebar,
          },
        },
      };
    },
    calendarapi() {
      return this.$refs.refCalendar.getApi();
    },
  },
  methods: {
    handleEventClick({ event }) {
      this.$emit("event:click", event.extendedProps);
    },
    handleData(info, successCallback) {
      if (!info) return;
      successCallback(this.data);
    },
    eventDrop({ event }) {
      this.$emit("event:drop", event.extendedProps);
    },
    showSidebar() {
      this.isSidebarActive = !this.isSidebarActive;
    },
    refetch() {
      this.calendarapi.refetchEvents();
    },
    eventDidMount(args) {
      if (args?.event?.extendedProps?.status) {
        args.el.classList.add(`bg-light-${this.$variants[args.event.extendedProps.status.key]}`);
      } else args.el.classList.add("bg-light-danger");
      const dotEl = args.el.getElementsByClassName("fc-daygrid-event-dot")[0];
      if (dotEl) dotEl.style.display = "none";
    },
    datesSet(dates) {
      const { start, end } = dates;
      this.$emit("dates:set", { startDate: start, endDate: end });
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.refetch();
      },
    },

    isVerticalMenuCollapsed() {
      setTimeout(() => {
        this.calendarapi.updateSize();
      }, 300);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
