<template>
  <b-row>
    <b-col md="6">
      <app-input
        v-model.number="employeeCount.greaterThan"
        type="number"
        name="Minimum"
        texticon="Min:"
        placeholder="Sayı Giriniz..."
        :rules="employeeCount.lessThan ? `max_value:${employeeCount.lessThan}` : ''"
      />
    </b-col>
    <b-col md="6">
      <app-input
        v-model.number="employeeCount.lessThan"
        type="number"
        name="Maksimum"
        texticon="Max:"
        placeholder="Sayı Giriniz..."
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      employeeCount: {
        greaterThan: null,
        lessThan: null,
      },
    }
  },
  watch: {
    employeeCount: {
      deep: true,
      handler() {
        this.$emit('input', this.employeeCount)
      },
    },
    value() {
      this.employeeCount = this.value
    },
  },
  mounted() {
    if (this.value) this.employeeCount = this.value
  },
}
</script>

<style></style>
