<template>
  <validation-provider
    #default="{ errors }"
    :name="name"
    :vid="type === 'password' ? type : name"
    :rules="rules"
  >
    <b-form-group
      :label-for="name"
      :label="label"
    >
      <b-input-group
        class="input-group-merge"
        :class="errors.length > 0 ? 'is-invalid' : null"
      >
        <b-input-group-prepend
          v-if="icon || texticon"
          is-text
        >
          <feather-icon
            v-if="icon"
            :icon="icon"
          /> <span v-if="texticon">{{ texticon }}</span>
        </b-input-group-prepend>
        <b-form-input
          :id="name"
          v-model="textinput"
          v-mask="[inputMask]"
          :placeholder="placeholder"
          :state="errors.length > 0 ? false : null"
          @input="$emit('input', textinput)"
        />
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  required, confirmed, min, max, min_value, max_value,
} from '@validations'
import { mask } from 'vue-the-mask'

export default {
  directives: {
    mask,
  },
  components: {
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    icon: {
      type: String,
    },
    texticon: {
      type: String,
    },
    inputMask: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String | Number,
    },
  },
  data() {
    return {
      required,
      min,
      max,
      min_value,
      max_value,
      confirmed,
      textinput: null,
    }
  },
  watch: {
    value(item) {
      this.textinput = item
    },
  },
  mounted() {
    this.textinput = this.value
  },
}
</script>
