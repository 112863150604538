<template>
  <app-select-input
    v-model="riskstate"
    label="Tehlike Sınıfı:"
    name="Tehlike Sınıfı"
    :options="options"
    select_label="value"
    placeholder="Tehlike Sınıfı Seçiniz..."
    :reduce="reduce"
    :rules="required ? 'required' : ''"
    :loading="loading"
    @input="$emit('input', riskstate)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String | Object,
    },
    reduce: {
      type: Function,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      riskstate: null,
      options: [],
      loading: false,
    }
  },
  watch: {
    value(item) {
      this.riskstate = item
    },
  },
  mounted() {
    this.riskstate = this.value
    this.getRiskStateOptions()
  },
  methods: {
    getRiskStateOptions() {
      this.loading = true
      this.$store
        .dispatch('getRiskStateOptions')
        .then(options => {
          this.options = options
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style></style>
