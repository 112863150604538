<template>
  <svg-icon
    type="mdi"
    :path="path"
    :size="size"
  />
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'

export default {
  components: {
    SvgIcon,
  },
  props: {
    icon: {
      type: String,
      default: 'Account',
    },
    size: {
      type: Number | String,
    },
  },
  computed: {
    path() {
      const { [`mdi${this.icon}`]: icon } = require('@mdi/js')
      return icon
    },
  },
}
</script>

<style></style>
