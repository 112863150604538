<template>
  <b-card class="border mb-50" body-class="p-1">
    <b-row style="gap: 10px 0px" class="mb-1">
      <b-col lg="8">
        <div class="d-flex gap-5 flex-wrap flex-1-md">
          <slot name="modalbutton" />
        </div>
      </b-col>
      <b-col lg="4">
        <div class="d-flex align-items-center gap-10">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input id="filterInput" v-model="filter" placeholder="Ara..." type="search" @input="handleInput" />
          </b-input-group>
          <b-button v-if="filterable" v-b-toggle="'filter-collapse' + id" variant="gradient-secondary" class="btn-icon rounded-circle" style="padding: 4px">
            <mdi-icon icon="FilterVariant" size="24" />
          </b-button>
          <!-- <app-icon-button v-if="filterable" v-b-toggle.filter-collapse variant="gradient-secondary" icon="FilterIcon" size="17" /> -->
        </div>
      </b-col>
    </b-row>
    <b-collapse :id="'filter-collapse' + id">
      <b-card body-class="p-1" class="border m-0 mb-1">
        <slot name="filtercollapse" />
      </b-card>
    </b-collapse>
    <b-table
      :hover="hover"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterIncludedFields"
      details-td-class="p-0"
      show-empty
      empty-text="Veri Bulunamadı"
      empty-filtered-text="Veri Bulunamadı"
      @filtered="onFiltered"
      @row-clicked="rowClicked"
    >
      <template v-for="field in fields" :slot="`cell(${field.key})`" slot-scope="data">
        <slot v-if="$scopedSlots[field.key]" :name="field.key" v-bind="data" />
        <slot v-else>
          {{ $showValue(data, field, "data.item.") }}
        </slot>
      </template>
      <template #row-details="row">
        <slot name="row-details" v-bind="row" />
      </template>
    </b-table>
    <b-row>
      <b-col class="d-flex align-items-center" cols="6" md="4">
        <div>
          <b-form-select id="perPageSelect" v-model="perPage" class="d-flex" size="sm" :options="pageOptions" />
        </div>
      </b-col>
      <b-col cols="6" md="4">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-50" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    hover: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10, 20, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      dateFilterInput: null,
      typingTimeout: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
    filterIncludedFields() {
      return this.fields.filter((f) => f.filterable).map((f) => f.key);
    },
  },
  watch: {
    items(list) {
      this.totalRows = list.length > 0 ? list.length : this.totalRows;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    rowClicked(row, index, event) {
      this.$emit("row-clicked", row, index, event);
    },
    handleInput() {
      // Clear the previous timeout
      clearTimeout(this.typingTimeout);

      // Set a new timeout to trigger the event after 1 second of inactivity
      this.typingTimeout = setTimeout(() => {
        this.$emit("searched", this.filter);
      }, 600);
    },
  },
  mounted() {
    this.totalRows = this.items.length;
  },
};
</script>
