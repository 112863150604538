<template>
  <app-select-input
    v-model="counties"
    :options="options"
    :reduce="(option) => option._id"
    :selectable="(option) => !option.hasOwnProperty('group')"
    label="İlçe:"
    select_label="value"
    name="İlçe"
    placeholder="İlçe Seçiniz..."
    :filter="filterOptions"
    :loading="loading"
    multiple
    @input="$emit('input', counties)"
  >
    <template #option="data">
      <div
        v-if="data.group"
        class="font-weight-bolder"
        style="cursor: pointer; color: black; opacity: 1"
      >
        {{ $capitalize(data.value) }}
        <hr class="m-0 mt-75">
      </div>
      <div
        v-else
        class="ml-75"
      >
        {{ $capitalize(data.value) }}
      </div>
    </template>
  </app-select-input>
</template>

<script>
import graphqlRequest from '@/utils/graphqlRequest'

export default {
  props: { value: Array | null },
  data() {
    return {
      options: [],
      loading: false,
      counties: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.counties = this.value
    })
  },
  methods: {
    getCounties(provinces) {
      this.loading = true
      this.options = []
      graphqlRequest('/optionData', {
        query: `
    query countyOfProvinces($provinces:[String!]!){
	    countyOfProvinces(provinces:$provinces){
        value
        counties{ _id value province{value} }
      }
    }
      `,
        variables: { provinces },
      })
        .then(({ countyOfProvinces }) => {
          countyOfProvinces.map(item => {
            this.options.push({ value: item.value, group: true })
            this.options = this.options.concat(item.counties)
            this.loading = false
          })
        })
        .catch(error => {
          this.loading = false
          console.log(error)
        })
    },
    cleanOptions() {
      this.options = []
      this.counties = []
      this.$emit('input', [])
    },
    checkOptionFitsSearch(value, search) {
      return value?.toLocaleLowerCase('tr')?.includes(search.toLocaleLowerCase('tr'))
    },
    reduce(option) {
      return option?._id
    },
    filterOptions(options, search) {
      return options.filter(item => {
        if (item.group) {
          if (options.some(option => this.checkOptionFitsSearch(option?.value, search) && option?.province?.value === item.value)) return item
        }
        return this.checkOptionFitsSearch(item?.province?.value, search) || this.checkOptionFitsSearch(item?.value, search)
      })
    },
  },
}
</script>

<style></style>
