export default {
  setAssignments: (state, assignments) => (state.assignments = assignments),
 /*  newAssignment: (state, assignment) => state.assignments.unshift(assignment),
  deactiveAssignment: (state, assignment) => {
    const index = state.assignments.findIndex((a) => a?._id === assignment?._id);
    state.assignments[index].isAssignmentActive = false;
    state.assignments[index].passivizationDate = assignment?.passivizationDate;
  }, */

  setWsExpertAssignments: (state, wsExpertAssignments) => (state.wsExpertAssignments = wsExpertAssignments),

  setPhysicianAssignments: (state, physicianAssignments) => (state.physicianAssignments = physicianAssignments),
};
