<template>
  <app-select-input
    v-model="osb"
    :reduce="reduce"
    name="OSB veya KSS"
    :options="osbList"
    select_label="name"
    label="Sanayi Bölgeleri:"
    placeholder="OSB veya KSS Seçiniz..."
    :selectable="(option) => !option.hasOwnProperty('group')"
    :filter="filterOptions"
    multiple
    @input="$emit('input', $event)"
  >
    <template #option="data">
      <div
        v-if="data.group"
        class="font-weight-bolder"
        style="cursor: pointer; color: black; opacity: 1"
      >
        {{ $capitalize(data.name) }}
        <hr class="m-0 mt-75">
      </div>
      <div v-else>
        <span class="text-muted"> ({{ data.type }}) </span>
        <span>{{ data.name }}</span>
      </div>
    </template>
  </app-select-input>
</template>

<script>
import graphqlRequest from '@/utils/graphqlRequest'

export default {
  props: {
    value: {
      type: Array,
    },
    provinces: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      osb: [],
      osbList: [],
    }
  },
  watch: {
    provinces: {
      deep: true,
      handler() {
        this.getOptions()
      },
    },
    value() {
      this.osb = this.value
    },
  },
  mounted() {
    this.getOptions()
    if (this.value) this.osb = this.value
  },
  methods: {
    getOptions() {
      graphqlRequest('/optionData', {
        query: `
        query OsbListOfProvinces($provinces:[String]){
          OsbListOfProvinces(provinces:$provinces){
            name osbList{_id name type province{value} }
          }
        }
        `,
        variables: { provinces: this.provinces },
      })
        .then(({ OsbListOfProvinces }) => {
          this.osbList = []
          OsbListOfProvinces.map(item => {
            this.osbList.push({ name: item.name, group: true })
            this.osbList = this.osbList.concat(item.osbList)
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    checkOptionFitsSearch(value, search) {
      return value?.toLocaleLowerCase('tr')?.includes(search.toLocaleLowerCase('tr'))
    },
    reduce(option) {
      return option?._id
    },
    filterOptions(options, search) {
      return options.filter(item => {
        if (item.group) {
          if (options.some(option => this.checkOptionFitsSearch(option?.name, search) && option?.province?.value === item.name)) return item
        }
        return this.checkOptionFitsSearch(item?.province?.value, search) || this.checkOptionFitsSearch(item?.name, search)
      })
    },
  },
}
</script>

<style></style>
