import formRequests from "./formRequests";
import settings from "./settings";
import users from "./users";
import options from "./options";

export default {
  ...formRequests,
  ...settings,
  ...users,
  ...options,
};
