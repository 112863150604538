import { emitter } from "@/global-prototypes";
import axios from "@/utils/appAxios";

export default {
  newVisit: ({ commit }, { data, actionType }) => {
    axios
      .post("/visits", data, { loading: "sidebar" })
      .then((response) => {
        commit(actionType, response.data);
        commit("cleanAppSidebar");
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Ziyaret Sisteme Başarıyla Eklendi.",
        });
      })
      .catch((error) => {
        const message = error?.status === 409 ? error?.data?.message : null;
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
          message,
        });
      });
  },
  createManyVisit: ({ commit }, { data, actionType }) => {
    axios
      .post("/visits/create-many", { data }, { loading: "sidebar" })
      .then((response) => {
        commit(actionType, response.data);
        commit("cleanAppSidebar");
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Ziyaretler Sisteme Başarıyla Eklendi.",
        });
      })
      .catch((error) => {
        const message = error?.status === 409 ? error?.data?.message : null;
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
          message,
        });
      });
  },
  getVisits: ({ commit }, { query, actionType }) => {
    axios
      .get("/visits/list/paginated", { params: { ...query }, loading: "table" })
      .then((response) => {
        commit(actionType, response.data);
      })
      .catch((error) => {
        emitter.$emit("Notification", { title: "Hata Oluştu.", message: error?.data?.message ?? null, variant: "danger" });
        console.log(error);
      });
  },
  setVisitCompleted({ commit }, id) {
    axios
      .patch(`/visits/${id}/completed`)
      .then((result) => {
        commit("setVisitCompleted", { id, status: result.data.status });
        emitter.$emit("Notification", {
          title: "Ziyaret Durumu Güncellendi.",
          variant: "success",
        });
      })
      .catch((err) => {
        emitter.$emit("Notification", { title: "Hata Oluştu.", message: err?.data?.message ?? null, variant: "danger" });
      });
  },
  updateVisitStartDate({ commit }, { id, startDate }) {
    axios
      .patch(`/visits/${id}/update-start-date`, { startDate }, { loading: "sidebar" })
      .then((result) => {
        commit("updateVisitStartDate", { id, startDate: result.data.startDate });
        commit("cleanAppSidebar");
        emitter.$emit("Notification", { title: "Ziyaret Tarihi Güncellendi.", variant: "success" });
      })
      .catch((err) => {
        emitter.$emit("Notification", { title: "Hata Oluştu.", message: err?.data?.message ?? null, variant: "danger" });
        console.log(err);
      });
  },
  updateVisitToNotCompleted({ commit }, { id, data }) {
    axios
      .patch(`/visits/${id}/not-completed`, data, { loading: "sidebar" })
      .then((result) => {
        commit("cleanAppSidebar");
        commit("updateVisitToNotCompleted", { id, data: result.data });
        emitter.$emit("Notification", { title: "Ziyaret Durumu Güncellendi.", variant: "success" });
      })
      .catch((err) => {
        emitter.$emit("Notification", { title: "Hata Oluştu.", message: err?.data?.message ?? null, variant: "danger" });
        console.log(err);
      });
  },
};
