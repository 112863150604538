import { Ability } from "@casl/ability";
import { initialAbility } from "./config";
import { getUserData } from "@/auth/utils";

//  Read ability from sessionStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update sessionStorage so be careful and please update this
const userData = getUserData();

export default new Ability(userData?.ability ?? initialAbility);
