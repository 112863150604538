import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  state: {
    invoicePayments: {
      data: [],
      total: 0,
    },
    selectedParasutContact: null,
    paymentCallback: null,
  },
  actions,
  mutations,
  getters,
};
