import Vue from "vue";
import VueRouter from "vue-router";
import appAxios from "@/utils/appAxios";
import main from "./routes";
import errors from "./routes/errors";
import auth from "./routes/auth";
import compaines from "./routes/companies";
import users from "./routes/users";
import web from "./routes/web";
import settings from "./routes/settings";
import finance from "./routes/finance";
import riskomer from "./routes/riskomer";
import { isUserLoggedIn } from "@/auth/utils";
import { canNavigate } from "@/libs/acl/routeProtection";
import store from "@/store";
import ability from "@/libs/acl/ability";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    ...main,
    ...errors,
    ...auth,
    ...users,
    ...compaines,
    ...web,
    ...settings,
    ...finance,
    ...riskomer,
    {
      name: "network-error",
      path: "/network-error",
      component: () => import("@/views/Additional/networkError.vue"),
    },
    {
      name: "dev",
      path: "/dev",
      component: () => import("@/views/dev/DevTabs.vue"),
    },
    {
      name: "dicom-test",
      path: "/dicom-test",
      component: () => import("@/views/Dashboard/CornerStoneDeneme.vue"),
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  from.meta.loadingLayout = "full";
  const isLoggedIn = isUserLoggedIn();
  if (!canNavigate(to)) {
    if (!isLoggedIn) {
      store.commit("cleanStore");
      return next({ name: "login" });
    }
    if (to.name !== "unauthorized") return router.push({ name: "unauthorized" }).catch(() => {});
  }
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next({ name: "dashboard" });
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(async (to, from) => {
  delete from?.meta?.loadingLayout;
  try {
    const result = await appAxios.get("/version/admin");
    const appVersion = result.data.version;
    const currentVersion = localStorage.getItem("appVersion");
    if (!currentVersion) localStorage.setItem("appVersion", appVersion);
    else if (appVersion?.toString() !== currentVersion?.toString()) {
      localStorage.setItem("appVersion", appVersion);
      window.location.reload();
    }

    const token = sessionStorage.getItem("token");
    if (token && from.name !== "login") {
      const r = await appAxios.get("/administrators/token-login");
      const user = r?.data?.user;
      sessionStorage.setItem("userData", JSON.stringify(user));
      sessionStorage.setItem("token", r?.data?.token ?? token);
      appAxios.defaults.headers.common.Authorization = r?.data?.token ?? token;
      store.commit("setUser", user);
      ability.update(user.ability);
      if (!user.first_login) router.push({ name: "first-login" }).catch(() => {});
    }
  } catch (error) {
    if (error?.status === 401) {
      store.dispatch("logout", { sendLogoutReq: false });
    }
    router.push({ name: "error-404" }).catch(() => {});
  }

  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) appLoading.style.display = "none";
});

export default router;
