export default {
  setPendingCallRequests: (state, items) => (state.pendingCallRequests = items),
  newWebCallRequest: (state, item) => {
    state.pendingCallRequests.push(item)
  },
  updateWebCallRequest: (state, data) => {
    if (data?.status !== 'beklemede') state.pendingCallRequests = state.pendingCallRequests.filter(item => item?._id !== data?._id)
    else {
      const index = state.pendingCallRequests.findIndex(item => item?._id === data?._id)
      if (index !== -1) state.pendingCallRequests.splice(index, 1, data)
    }
  },
  setRequestHistory: (state, items) => (state.requestHistory = items),
  updateRequestHistory: (state, data) => {
    if (data?.status !== 'beklemede') {
      const index = state.requestHistory.findIndex(item => item?._id === data?._id)
      if (index !== -1) state.requestHistory.splice(index, 1, data)
      else state.requestHistory.push(data)
    }
  },
}
