<template>
  <app-select-input
    v-model="osgb"
    name="OSGB"
    label="OSGB:"
    :options="options"
    select_label="name"
    placeholder="OSGB Seçiniz..."
    :reduce="(option) => option._id"
    multiple
    @input="$emit('input', $event)"
  />
</template>

<script>
import graphqlRequest from '@/utils/graphqlRequest'

export default {
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      osgb: [],
      options: [],
    }
  },
  watch: {
    value() {
      this.osgb = this.value
    },
  },
  mounted() {
    this.getOptions()
    if (this.value) this.osgb = this.value
  },
  methods: {
    getOptions() {
      graphqlRequest('/optionData', {
        query: `
        { OsgbList{ _id name } }
        `,
      })
        .then(({ OsgbList }) => {
          this.options = OsgbList
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style></style>
