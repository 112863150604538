const routes = ["employees", "admins", "tranings", "performance", "izin", "zimmet", "mesai"].map((route, index) => ({
  path: `/riskomer/${route}`,
  name: `riskomer-${route}`,
  props: true,
  component: () => import("@/views/Riskomer/RiskomerTabs.vue"),
  props: { tabIndex: index },
  meta: {
    requiresAuth: true,
  },
}));

export default [...routes];
