import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { cleanFields } from "@/global-prototypes";

// Modules
import SecureLS from "secure-ls";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

// App State Modules
import appState from "./modules/app-state";
import optionData from "./modules/optionData";
import companies from "./modules/companies";
import assignments from "./modules/assignments";
import auth from "./modules/auth";
import employees from "./modules/employees";
import visits from "./modules/visits";
import physicians from "./modules/physicians";
import customers from "./modules/customers";
import branches from "./modules/branches";
import webCampaigns from "./modules/webCampaigns";
import confirms from "./modules/confirms";
import riskomerEmployees from "./modules/riskomerEmployees";
import isg from "./modules/isg";
import notes from "./modules/notes";
import central from "./modules/central";
import finance from "./modules/finance";

// Secure LS
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

const appStateModules = {
  appState,
  optionData,
  companies,
  branches,
  assignments,
  auth,
  employees,
  finance,
  visits,
  physicians,
  customers,
  webCampaigns,
  confirms,
  riskomerEmployees,
  isg,
  notes,
  central,
};

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    ...appStateModules,
  },
  mutations: {
    cleanStore: (state) => {
      Object.keys(appStateModules).map((key) => (state[key] = cleanFields(state[key])));
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userData");
    },
  },
  strict: process.env.DEV,
  plugins: [
    createPersistedState({
      paths: ["companies", "branches", "auth", "finance"],
      storage: window.sessionStorage,
    }),
  ],
});
