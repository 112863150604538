<template>
  <div class="d-flex align-items-center mr-2">
    <b-avatar
      variant="light-primary"
      rounded
    >
      <feather-icon
        :icon="icon"
        size="18"
      />
    </b-avatar>
    <div class="ml-1">
      <small style="white-space: nowrap">{{ title }}</small>
      <h5 class="mb-0">
        {{ count }}
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: String | Number,
    },
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
}
</script>

<style></style>
