<template>
  <validation-provider
    #default="{ validate, errors }"
    vid="description"
    :name="name"
    rules="required|size:1024|mimes:application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  >
    <b-row class="d-flex align-items-center">
      <b-col
        cols="5"
        offset="1"
      >
        <label class="file-input">
          <input
            type="file"
            style="display: none"
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            @change="validate($event), handleChange($event)"
          >
          <span class="btn btn-gradient-primary p-50"><feather-icon icon="PaperclipIcon" /> Dosya Seç</span>
        </label>
      </b-col>
      <b-col cols="6">
        <div
          v-if="file"
          class="d-flex align-items-center gap-10"
        >
          <b-img
            width="28"
            rounded
            :src="require('@/assets/images/icons/doc.png')"
          />
          <div class="d-flex flex-column">
            <span> {{ file.name }} </span>
            <small v-if="file.size < 1024"> {{ file.size }} Byte</small>
            <small v-else-if="file.size < 1024000"> {{ (file.size / 1000).toFixed(0) }} KB</small>
            <small v-else> {{ (file.size / 1000000).toFixed(1) }} MB</small>
          </div>
        </div>
      </b-col>
    </b-row>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required, size, mimes } from '@validations'

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: File,
    },
    name: {
      type: String,
      default: 'Dosya',
    },
  },
  data() {
    return {
      file: null,

      // rules
      required,
      size,
      mimes,
    }
  },
  methods: {
    handleChange(event) {
      event.target.files[0] ? (this.file = event.target.files[0]) : (this.file = null)
      this.$emit('input', this.file)
    },
  },
}
</script>

<style></style>
