<template>
  <div>
    <app-select-input
      v-model="province"
      :options="options"
      name="İl"
      label="İl:"
      placeholder="İl Seçiniz..."
      select_label="value"
      :rules="required ? 'required' : ''"
      :reduce="reduce"
      :multiple="multiple"
      @input="provinceSelected"
      @search:blur="$emit('search:blur')"
    >
      <template v-if="multiple" #list-header>
        <div class="p-50 m-25 border rounded d-flex gap-3">
          <app-button text="Tümünü Seç" @click="selectAll" />
          <app-button text="Seçimleri Kaldır" @click="deSelectAll" />
        </div>
      </template>
    </app-select-input>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String | Object,
    },
    required: { type: Boolean, default: false },
    reduce: { type: Function },
    multiple: { type: Boolean, default: false },
  },
  data() {
    return {
      province: [],
      options: [],
    };
  },
  watch: {
    value(item) {
      this.province = item;
    },
  },
  mounted() {
    this.getProvinces();
    this.province = this.value;
    this.provinceSelected(this.province);
  },
  methods: {
    getProvinces() {
      this.$axios
        .get("option-data/province")
        .then((response) => {
          this.options = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    provinceSelected(item) {
      this.$emit("input", item);
      this.$emit("selected", item);
    },
    selectAll() {
      this.province = [];
      const options = this.reduce ? this.reduceItems(this.options) : this.options;
      this.province = [...options];
      this.$emit("input", this.province);
    },
    deSelectAll() {
      this.province = [];
      this.$emit("input", this.province);
    },
    reduceItems(items) {
      return items.map(this.reduce);
    },
  },
};
</script>

<style></style>
