import axios from '@/utils/appAxios'
import { emitter } from '@/global-prototypes'

export default {
  getData({ commit }) {
    axios
      .get('web/form')
      .then(response => {
        commit('setRequests', response.data)
      })
      .catch(() => {
        emitter.$emit('Notification', {
          variant: 'danger',
          title: 'Hata oluştu',
        })
      })
  },
}
