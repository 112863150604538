<template>
  <b-row>
    <b-col
      v-for="(item, key) in data"
      :key="key"
      cols="6"
    >
      <app-input
        v-model.number="item.percent"
        v-mask="['##']"
        name="İndirim"
        icon="PercentIcon"
        :label="item.label + ':'"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      data: [
        { label: 'Yıllık Peşin Ödeme İndirimi', percent: 0 },
        { label: 'Taahhütlü Aylık Ödeme İndirimi', percent: 0 },
      ],
    }
  },
  watch: {
    data: {
      handler() {
        this.$emit('input', this.data)
      },
      deep: true,
    },
  },
  mounted() {
    if (this.value && this.value.length > 0) this.data = this.value
  },
}
</script>

<style></style>
