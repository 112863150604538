import formRequests from "./formRequests";
import settings from "./settings";
import users from "./users";

export default {
  ...formRequests,
  ...settings,
  ...users,
  activeLoadingOverlay: (state) => (state.loadingOverlay = true),
  disableLoadingOverlay: (state) => (state.loadingOverlay = false),
  activeSidebarOverlay: (state) => (state.sidebarOverlay = true),
  disableSidebarOverlay: (state) => (state.sidebarOverlay = false),
  activeModalOverlay: (state) => (state.modalOverlay = true),
  disableModalOverlay: (state) => (state.modalOverlay = false),
  activeTabOverlay: (state) => (state.tabOverlay = true),
  disableTabOverlay: (state) => (state.modalOverlay = false),
  activeSelectBoxOverlay: (state) => (state.selectBoxOverlay = true),
  disableSelectBoxOverlay: (state) => (state.selectBoxOverlay = false),
  setAppSidebarOptions: (state, options) => {
    state.appSidebar = {
      ...state.appSidebar,
      ...options,
    };
  },
  cleanAppSidebar: (state) => {
    state.appSidebar = {
      show: false,
    };
  },
  setAppModalOptions: (state, options) => {
    state.appModal = {
      ...state.appModal,
      ...options,
    };
  },
  setAppModalSidebarOptions: (state, options) => {
    state.appModal = {
      ...state.appModal,
      sidebar: {
        ...state.appModal.sidebar,
        ...options,
      },
    };
  },
  cleanAppModal: (state) => {
    if (state?.appModal?.show) state.appModal.show = false;
    setTimeout(() => {
      state.appModal = {
        show: false,
        sidebar: {
          show: false,
        },
      };
    }, 300);
  },
  cleanAppModalSidebar: (state) => {
    state.appModal = {
      ...state.appModal,
      sidebar: {
        show: false,
      },
    };
  },
};
