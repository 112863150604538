<template>
  <validation-provider
    #default="{ validate, errors }"
    vid="description"
    :name="name"
    :rules="`${requires ? 'required|' : ''}size:${sizeMBLimit * 1024}${accept ? '|mimes:' + accept : ''}`"
  >
    <small>{{ label }} </small>
    <b-form-file
      :state="errors.length > 0 ? false : null"
      placeholder="Dosya seçin veya bu alana sürükleyin..."
      drop-placeholder="Dosyayı buraya bırakın..."
      :accept="accept"
      :multiple="multiple"
      :class="{ 'mt-25': label }"
      @change="handleChange($event, validate)"
    />
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { required, size, mimes } from "@validations";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    accept: {
      type: String,
    },
    name: {
      type: String,
      default: "Dosya",
    },
    label: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    sizeMBLimit: {
      type: Number,
      default: 2,
    },
    requires: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileinput: null,

      // rules
      required,
      size,
      mimes,
    };
  },
  methods: {
    async handleChange(event, validate) {
      const input = event.target;
      if (input.files && input.files.length > 0) {
        if (this.multiple) this.$emit("input", await this.resizeImages(input.files));
        else if (input.files.length === 1) {
          const file = input.files[0];
          file.type.includes("image") ? this.$emit("input", await this.resizeImage(file)) : (this.$emit("input", file), (this.fileinput = file));
        } else if (input.files.length > 1) {
          const files = input.files;
          this.$emit("input", await this.resizeImages(files));
        }
      }
      validate(this.fileinput);
    },
    resizeImage(file) {
      const maxSize = 1400;
      return new Promise((resolve, reject) => {
        const image = new Image();
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        image.src = URL.createObjectURL(file);

        image.onload = () => {
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const compressedFile = new File([blob], file.name, { type: file.type });
            this.fileinput = compressedFile;
            resolve(compressedFile);
          }, file.type);
        };
      });
    },
    async resizeImages(files) {
      const newFiles = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type.includes("image")) {
          newFiles.push(await this.resizeImage(file));
        } else newFiles.push(file);
      }
      this.fileinput = newFiles;
      return newFiles;
    },
  },
};
</script>

<style></style>
