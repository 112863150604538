let webActionRoutes = ['campaigns', 'prices', 'announces', 'option-data', 'coupons']

webActionRoutes = webActionRoutes.map((route, index) => ({
  path: `/web-actions/${route}`,
  name: `web-actions-${route}`,
  component: () => import('@/views/WebManagment/actions/index.vue'),
  props: { tabIndex: index },
  meta: {
    requiresAuth: true,
  },
}))

export default [
  ...webActionRoutes,
  {
    path: '/web/ayarlar',
    name: 'web-ayarlar',
    component: () => import('@/views/Describings/index.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/web-informations',
    name: 'web-informations',
    component: () => import('@/views/WebManagment/informations/index.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/price-offers/other',
    name: 'price-offers-other',
    component: () => import('@/views/WebManagment/informations/OtherPriceOffers.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
