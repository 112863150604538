export default {
  setPhysicians: (state, physicians) => (state.physicians = physicians),
  setNewPhysician: (state, physician) => state.physicians.push(physician),
  setPhysicianData: (state, physician) => (state.physicianData = physician),
  updatePhysician: (state, physician) => {
    const index = state.physicians.findIndex(item => item._id === physician._id)
    if (index !== -1) {
      state.physicians.splice(index, 1, physician)
    }
  },
}
