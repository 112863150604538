import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  state: {
    branchData: null,
    selectedBranch: null,
    riskomerBranches: [],
    branches: [],
    branchLocations: [],
  },
  actions,
  mutations,
  getters,
}
