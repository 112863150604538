import { emitter } from '@/global-prototypes'
import axios from '@/utils/appAxios'

export default {
  getPendingConfirms: ({ commit }) => {
    axios
      .get('/confirms', { params: { status: 'beklemede' }, loading: 'table' })
      .then(response => {
        commit('setPendingConfirms', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  getConfirmHistory: ({ commit }) => {
    axios
      .get('/confirms', { params: { status: ['onaylandı', 'reddedildi'] }, loading: 'table' })
      .then(response => {
        commit('setConfirmHistory', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },

  updateConfirm: ({ commit }, { id, type }) => {
    axios
      .patch(`/confirms/${id}/${type}`)
      .then(result => {
        commit('setUpdatedConfirm', result.data)
        emitter.$emit('Notification', {
          variant: 'success',
          title: type === 'accept' ? 'Kayıt onaylandı.' : 'Kayıt reddedildi.',
        })
      })
      .catch(err => {
        console.log(err)
      })
  },
}
