import { emitter } from "@/global-prototypes";
import appAxios from "@/utils/appAxios";

export default {
  downloadPaymentReceipt(_, { id, loading }) {
    appAxios
      .get(`/finance/invoice-payments/download-payment-receipt/${id}`, { loading: loading ?? "table" })
      .then((result) => {
        if (result?.data?.url) window.open(result?.data?.url, "_blank");
      })
      .catch((err) => {
        emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message });
      });
  },
  getParasutContact({ commit }, { id, callback }) {
    appAxios
      .get(`/finance/parasut-companies/get-one/`, { params: { contact_id: id }, loading: "table" })
      .then((result) => {
        commit("setSelectedParasutContact", result.data);
        callback && callback(true);
      })
      .catch((err) => {
        console.log(err);
        callback && callback(false);
      });
  },
};
