<template>
  <div>
    <b-table
      details-td-class="p-0"
      :hover="hover"
      responsive
      :per-page="pagination ? perPage : null"
      :current-page="pagination ? currentPage : null"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="onFilter"
      :filter-included-fields="filterIncludedFields"
      show-empty
      empty-text="Veri Bulunamadı"
      empty-filtered-text="Veri Bulunamadı"
      @filtered="onFiltered"
      @row-clicked="rowClicked"
      :striped="false"
    >
      <template v-for="field in fields" :slot="`cell(${field.key})`" slot-scope="data">
        <slot v-if="$scopedSlots[field.key]" :name="field.key" v-bind="data" />
        <slot v-else>
          {{ $showValue(data, field, "data.item.") }}
        </slot>
      </template>
      <template #row-details="row">
        <slot name="row-details" v-bind="row" />
      </template>
    </b-table>
    <b-row v-if="pagination">
      <b-col class="d-flex align-items-center" cols="6" md="4">
        <div>
          <b-form-select id="perPageSelect" v-model="perPage" class="d-flex" size="sm" :options="pageOptions" />
        </div>
      </b-col>
      <b-col cols="6" md="4">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-50" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    hover: {
      type: Boolean,
      default: false,
    },
    onFilter: {
      type: String,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10, 20, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      typingTimeout: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
    filterIncludedFields() {
      return this.fields.filter((f) => f.filterable).map((f) => f.key);
    },
  },
  watch: {
    items(list) {
      this.totalRows = list.length > 0 ? list.length : this.totalRows;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    rowClicked(row, index, event) {
      this.$emit("row-clicked", row, index, event);
    },
  },
};
</script>
