import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  state: {
    visit: null,
    visits: [],
    planedVisits: [],
    notCompletedVisits: [],
    completedVisits: [],
  },
  actions,
  mutations,
  getters,
}
