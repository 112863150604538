<template>
  <div v-if="currentTab === 0 || currentTab > 0" ref="tabContainer" class="tab-container">
    <div ref="tabScroll" class="tab-scroll">
      <div :class="{ 'd-none': windowWidth > 479 }" class="mx-50">
        <b-dropdown :text="tabs[currentTab].title" variant="gradient-primary" center menu-class="w-100" block>
          <template v-for="(item, key) in tabs">
            <b-dropdown-item v-if="item && checkCan(item)" @click="routeCheck(null, item, key)" :key="key" :active="key === currentTab">{{ item.title }}</b-dropdown-item>
          </template>
        </b-dropdown>
        <hr class="m-0 my-50" />
      </div>
      <b-tabs :id="id" ref="tabs" v-model="currentTab" :nav-class="{ 'arrow-gap': showArrows, 'scrollable-tabs mb-0': true, 'pb-1': windowWidth > 480 }" pills :lazy="lazy">
        <template v-for="(item, key) in tabs">
          <b-tab
            v-if="item"
            :key="key"
            :button-id="'tab-button' + key"
            :title="item.title"
            :lazy="item.lazy ? item.lazy : lazyOnce ? !mountedTabs.includes(key) : false"
            @click="routeCheck($event, item, key)"
            :title-item-class="{ 'd-none': windowWidth < 480 || !checkCan(item) }"
            :disabled="!checkCan(item)"
          >
            <slot v-if="!item.component" :name="item.key || key" />
            <component :is="item.component" v-else />
          </b-tab>
        </template>
      </b-tabs>
    </div>
    <Transition name="fade">
      <div v-show="showArrows" ref="leftArrow" class="tab-arrow tab-arrow-left">
        <app-icon-button
          :gap="windowWidth < 767 ? 'sm' : ''"
          :size="windowWidth < 767 ? '16' : '15'"
          icon="ChevronLeftIcon"
          variant="gradient-primary"
          @click="scrollTabs('left')"
        />
      </div>
    </Transition>
    <Transition name="fade">
      <div v-show="showArrows" ref="rightArrow" class="tab-arrow tab-arrow-right">
        <app-icon-button
          :gap="windowWidth < 767 ? 'sm' : ''"
          :size="windowWidth < 767 ? '16' : '15'"
          icon="ChevronRightIcon"
          variant="gradient-primary"
          @click="scrollTabs('right')"
        />
      </div>
    </Transition>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    tabIndex: {
      type: Number,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    lazyOnce: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: uuidv4(),
    },
  },
  data() {
    return {
      showArrows: false,
      mountedTabs: [],
      currentTab: null,
    };
  },
  computed: {
    isVerticalMenuCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
  watch: {
    isVerticalMenuCollapsed() {
      setTimeout(() => {
        this.checkOverflow();
      }, 300);
    },
    tabIndex() {
      this.currentTab = this.tabIndex;
    },
    windowWidth() {
      this.checkOverflow();
    },
  },
  methods: {
    routeCheck(event, item, key) {
      if (this.lazyOnce) {
        if (!this.mountedTabs.includes(key)) {
          this.mountedTabs.push(key);
        }
      }
      this.currentTab = key;
      item?.route && item?.route !== this.$route.name && this.$router.push({ name: item.route });
      setTimeout(() => {
        event && event?.target?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }, 200);
    },
    scrollTabs(direction) {
      const checkIDElement = document.getElementById(`${this.id}__BV_tab_controls_`);
      const tabNav = checkIDElement || document.querySelector(".scrollable-tabs");
      const scrollAmount = direction === "left" ? -200 : 200;
      tabNav.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    },
    checkOverflow() {
      setTimeout(() => {
        const tabScrollContainer = this.$refs.tabScroll;
        const checkIDElement = document.getElementById(`${this.id}__BV_tab_controls_`);
        const tabNav = checkIDElement || document.querySelector(".scrollable-tabs");
        this.showArrows = tabScrollContainer.clientWidth < tabNav.scrollWidth;
      }, 300);
    },
    checkCan(item) {
      const action = item?.action ?? "read";
      if (!item?.resource) return true;
      return this.$can(action, item?.resource);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.mountedTabs.push(this.tabIndex ?? 0);
      this.currentTab = this.tabIndex ?? 0;

      this.checkOverflow();
      setTimeout(() => {
        const el = document.getElementById(`tab-button${this.tabIndex}`);
        el && el?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }, 200);
    });
  },
};
</script>

<style>
.tab-container {
  position: relative;
}

.tab-scroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
}

.tab-scroll::-webkit-scrollbar {
  width: 0;
}

.arrow-gap {
  padding: 0 45px !important;
}

.tab-arrow {
  position: absolute;
  top: 0;
  transition: linear 0.2s;
  padding: 4px;
}

@media screen and (max-width: 767px) {
  .arrow-gap {
    padding: 0 40px !important;
  }
  .tab-arrow {
    padding: 6px;
  }
}

.tab-arrow-left {
  left: 0%;
}

.tab-arrow-right {
  right: 0%;
}
</style>
