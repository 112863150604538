import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  state: {
    loadingOverlay: false,
    sidebarOverlay: false,
    modalOverlay: false,
    tabOverlay: false,
    selectBoxOverlay: false,
    requests: [],
    campaignAnnounces: [],
    individualCustomers: [],
    roles: [],
    appSidebar: {
      show: false,
    },
    appModal: {
      show: false,
      sidebar: {
        show: false,
      },
    },
  },
  mutations,
  getters,
  actions,
};
