import axios from "@/utils/appAxios";
import { emitter } from "@/global-prototypes";

export default {
  getEmployees: ({ commit }, { query, loading }) => {
    axios
      .get("/employees/list/paginated", { params: query, loading: loading ?? "table" })
      .then((result) => {
        commit("setEmployees", result.data);
      })
      .catch((err) => {
        emitter.$emit("Notification", { variant: "danger", title: "Hata oluştu", message: err?.data?.message ?? null });
        console.log(err);
      });
  },
  getCompanyEmployees: ({ commit }, { query, loading }) => {
    axios
      .get("/employees/employee-options", { params: query, loading: loading ?? "sidebar" })
      .then((result) => {
        commit("setEmployeeOptions", result.data);
      })
      .catch((err) => {
        emitter.$emit("Notification", { variant: "danger", title: "Hata oluştu", message: err?.data?.message ?? null });
      });
  },
  newEmployee({ commit }, data) {
    axios
      .post("/employees", data, { loading: "sidebar" })
      .then((response) => {
        commit("setNewEmployee", response.data);
        commit("cleanAppSidebar");
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Çalışan başarıyla sisteme eklendi.",
        });
      })
      .catch((error) => {
        console.log(error);
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata oluştu",
          message: error?.data?.message ?? null,
        });
      });
  },
  updateEmployee({ commit, getters }, { id, data }) {
    axios
      .patch(`/employees/${id}`, data, { loading: "sidebar" })
      .then((response) => {
        if (getters?.appModalItem?._id === id) commit("setAppModalOptions", { item: response.data });
        commit("setUpdatedEmployee", response.data);
        commit("cleanAppModalSidebar");
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Çalışan başarıyla güncellendi.",
        });
      })
      .catch((error) => {
        console.log(error);
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata oluştu",
          message: error?.data?.message ?? null,
        });
      });
  },
  createFromFile({ commit }, { modal, data }) {
    axios
      .post("/employees/create-from-file", data)
      .then((response) => {
        commit("setManyEmployees", response.data);
        modal.hide();
      })
      .catch((error) => {
        console.log(error);
      });
  },
  archiveEmployee({ commit, getters }, id) {
    axios
      .patch(`/employees/make-archived/${id}`, {}, { loading: "table" })
      .then((res) => {
        if (getters?.appModalItem?._id === id) getters.appModalItem.isArchived = true;
        commit("setEmployeeArchived", id);
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Çalışan başarıyla arşivlendi.",
        });
      })
      .catch((err) => {
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata oluştu",
          message: err?.data?.message ?? null,
        });
      });
  },
  getEmployeeFiles: ({ commit }, { query, loading }) => {
    axios
      .get("/employees/employee-files/paginated-list", { params: query, loading: loading ?? "modal" })
      .then((result) => {
        commit("setCompanyEmployeeFiles", result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  uploadEmployeeFile({ commit }, { data }) {
    axios
      .post("/employees/upload-employee-file", data, { loading: "sidebar" })
      .then((result) => {
        commit("setNewCompanyEmployeeFile", result.data);
        commit("cleanAppModalSidebar");
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Dosya başarıyla yüklendi.",
        });
      })
      .catch((err) => {
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
          message: err?.data?.message ?? null,
        });
      });
  },
  deleteEmployeeFile({ commit }, { id }) {
    axios
      .delete(`employees/employee-files/${id}`, { loading: "modal" })
      .then(() => {
        commit("deleteEmployeeFile", id);
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Dosya başarıyla silindi.",
        });
      })
      .catch((err) => {
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
          message: err?.data?.message ?? null,
        });
      });
  },
};
