import { emitter } from '@/global-prototypes'
import graphqlRequest from '@/utils/graphqlRequest'
import appAxios from '@/utils/appAxios'

export default {
  getSectors: ({ commit }) => {
    appAxios
      .get('/option-data/sectors', { loading: 'table' })
      .then(response => {
        commit('setSectors', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  getOsbList: (_, province) => new Promise((resolve, reject) => {
    graphqlRequest(
      '/optionData',
      {
        query: `
          { 
              OsbList(province: "${province}"){ _id name }
          }`,
      },
      { loading: 'table' },
    )
      .then(({ OsbList }) => {
        resolve(OsbList)
      })
      .catch(error => {
        reject(error)
      })
  }),
  newOsgb: ({ commit }, data) => {
    appAxios
      .post('/option-data/osgb', data, { loading: 'sidebar' })
      .then(response => {
        commit('setNewOsgb', response.data)
        commit('cleanAppSidebar')
        emitter.$emit('Notification', {
          variant: 'success',
          title: 'İşlem Başarılı',
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  updateOsgb: ({ commit }, { id, data }) => {
    appAxios
      .patch(`/option-data/osgb/${id}`, data, { loading: 'sidebar' })
      .then(result => {
        commit('updateOsgb', result.data)
        commit('cleanAppSidebar')
        emitter.$emit('Notification', {
          variant: 'success',
          title: 'İşlem Başarılı',
        })
      })
      .catch(err => {
        console.log(err)
      })
  },
  newOsb: ({ commit }, data) => {
    appAxios
      .post('/option-data/osb', data, { loading: 'sidebar' })
      .then(result => {
        commit('setNewOsb', result.data)
        commit('cleanAppSidebar')
        emitter.$emit('Notification', {
          variant: 'success',
          title: 'İşlem Başarılı',
        })
      })
      .catch(err => {
        console.log(err)
      })
  },
  updateOsb: ({ commit }, { id, data }) => {
    appAxios
      .patch(`/option-data/osb/${id}`, data, { loading: 'sidebar' })
      .then(result => {
        commit('updateOsb', result.data)
        commit('cleanAppSidebar')
        emitter.$emit('Notification', {
          variant: 'success',
          title: 'İşlem Başarılı',
        })
      })
      .catch(err => {
        console.log(err)
      })
  },
}
