import { emitter } from '@/global-prototypes'
import axios from '@/utils/appAxios'

export default {
  getIndCustomers: ({ commit }) => {
    axios
      .get('/individual-customer', { loading: 'table' })
      .then(response => {
        commit('setIndCustomers', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  newIndCustomer: ({ commit }, data) => {
    axios
      .post('/individual-customer', data, { loading: 'sidebar' })
      .then(response => {
        commit('setNewIndCustomer', response.data)
        commit('cleanAppSidebar')
      })
      .catch(error => {
        if (error?.status === 409) {
          emitter.$emit('Notification', { variant: 'danger', title: 'Hata Oluştu', message: 'Kullanıcı sistemde mevcut ' })
        }
      })
  },
}
