import router from "@/router";
import axios from "@/utils/appAxios";
import { emitter } from "@/global-prototypes";
import app from "@/main";
import { initialAbility } from "@/libs/acl/config";
import { getUserData } from "@/auth/utils";

export default {
  login: ({ commit }, data) => {
    axios
      .post("/administrators/login", data, { loading: "table" })
      .then((response) => {
        const user = response.data;
        sessionStorage.setItem("token", user.token);
        axios.defaults.headers.common.Authorization = user.token;
        commit("setUser", user);
        delete user.token;
        sessionStorage.setItem("userData", JSON.stringify(user));
        app.$ability.update(user?.ability ?? initialAbility);
        router.push({
          name: !user.first_login ? "first-login" : "dashboard",
        });
      })
      .catch((error) => {
        emitter.$emit("Notification", {
          title: "Giriş Yapılamadı",
          variant: "danger",
        });
      });
  },
  logout: async ({ commit }, { sendLogoutReq }) => {
    try {
      const token = sessionStorage.getItem("token");
      if (token && sendLogoutReq) await axios.get("/logout");
      commit("cleanStore");
      router.push({ name: "login" }).catch(() => {});
      app.$ability.update(initialAbility);
    } catch (error) {
      if (error?.status === 401) {
        commit("cleanStore");
        router.push({ name: "login" }).catch(() => {});
        app.$ability.update(initialAbility);
      }
    }
  },
  changeTempPassword: ({ commit }, data) => {
    axios
      .patch("/administrators/change-temp-password", data, { loading: "table" })
      .then(() => {
        const user = getUserData();
        user.first_login = true;
        sessionStorage.setItem("userData", user);
        commit("setUser", user);
        router.push({ name: "dashboard" });
        emitter.$emit("Notification", {
          title: "Giriş Başarılı",
          variant: "success",
        });
      })
      .catch(() => {
        emitter.$emit("Notification", {
          title: "Hata Oluştu",
          variant: "danger",
        });
      });
  },
};
