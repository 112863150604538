<template>
  <div>
    <b-form-checkbox-group
      v-model="riskstates"
      class="d-flex justify-content-center flex-wrap gap-5 py-50"
    >
      <b-form-checkbox
        v-for="(item, key) in riskOptions"
        :key="key"
        :value="item._id"
      >
        {{ item.value }}
      </b-form-checkbox>
    </b-form-checkbox-group>
  </div>
</template>

<script>
import graphqlRequest from '@/utils/graphqlRequest'

export default {
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      riskstates: [],
      riskOptions: [],
    }
  },
  watch: {
    riskstates: {
      deep: true,
      handler(items) {
        this.$emit('input', items)
      },
    },
    value() {
      this.riskstates = this.value
    },
  },
  mounted() {
    this.getOptions()
    if (this.value) this.riskstates = this.value
  },
  methods: {
    getOptions() {
      graphqlRequest('/optionData', { query: '{ riskStates{ _id value } }' })
        .then(({ riskStates }) => {
          this.riskOptions = riskStates
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style></style>
