export default [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/Dashboard/index.vue"),
    meta: {
      requiresAuth: true,
      resource: "dashboard",
    },
  },
  {
    path: "/visits",
    name: "visits",
    component: () => import("@/views/Visits/VisitTabs.vue"),
    meta: {
      requiresAuth: true,
      resource: "work-safety",
      action: "read",
    },
  },
  {
    path: "/visit-detail/:id",
    name: "visit-detail",
    props: true,
    component: () => import("@/views/Visits/details/VisitDetailModal.vue"),
    meta: {
      requiresAuth: true,
      resource: "work-safety",
      action: "read",
    },
  },
  {
    path: "/confirms",
    name: "confirms",
    component: () => import("@/views/Confirms/list.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support",
    name: "support",
    component: () => import("@/views/Support/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/central",
    name: "central",
    component: () => import("@/views/Central/CentralTabs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/central/history",
    name: "central-history",
    component: () => import("@/views/Central/WebCallRequests/WebCallRequestHistory.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/customer-logs",
    name: "customer-logs",
    component: () => import("@/views/Logs/userLogs/userLogList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/web-requests",
    name: "web-requests",
    component: () => import("@/views/WebManagment/informations/webRequests/list.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("@/views/chat/Chat.vue"),
    meta: {},
  },
];
