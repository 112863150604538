import { emitter } from '@/global-prototypes'
import graphqlRequest from '@/utils/graphqlRequest'
import campaignSidebar from '@/views/WebManagment/actions/campaigns/sidebar/sidebar.vue'

export default {
  newWebCampaign: async ({ commit }, data) => {
    try {
      const { newCampaign } = await graphqlRequest(
        '/web-campaigns',
        {
          query: `
        mutation newCampaign(
          $name:String!,
          $startDate:String!,
          $endDate:String!,
          $status:Boolean,
          $percent: Int!,
          $sectors: [String],
          $riskStates:[String],
          $areas:AreaInput,
          $employeeCount:EmployeeCountInput,
          $osgb:[String],
          $osb:[String]
          ){
          newCampaign(
            name: $name,
            startDate:$startDate,
            endDate:$endDate,
            status:$status,
            percent:$percent,
            employeeCount: $employeeCount,
            sectors: $sectors,
            areas:$areas,
            riskStates:$riskStates,
            osgb:$osgb,
            osb:$osb
            ){
            _id name status startDate endDate
          }
        }
      `,
          variables: data,
        },
        { loading: 'sidebar' },
      )
      commit('newWebCampaign', newCampaign)
      commit('cleanAppSidebar')
      emitter.$emit('Notification', { variant: 'success', title: 'İşlem Başarılı' })
    } catch (error) {
      console.log(error)
    }
  },
  updateWebCampaign: async ({ commit }, data) => {
    try {
      const { updateCampaign } = await graphqlRequest(
        '/web-campaigns',
        {
          query: `
        mutation updateCampaign(
          $_id:String!,
          $name:String!,
          $startDate:String!, 
          $endDate:String!,
          $status:Boolean,
          $percent: Int!,
          $employeeCount:EmployeeCountInput,
          $sectors: [String],
          $riskStates:[String],
          $areas:AreaInput,
          $osgb:[String],
          $osb:[String]
          ){
          updateCampaign(
            _id:$_id,
            name: $name,
            startDate:$startDate,
            endDate:$endDate,
            status:$status,
            employeeCount: $employeeCount,
            percent: $percent,
            sectors: $sectors,
            areas:$areas,
            riskStates:$riskStates,
            osgb:$osgb,
            osb:$osb
            ){
            _id name status startDate endDate
          }
        }
      `,
          variables: data,
        },
        { loading: 'sidebar' },
      )
      commit('updateWebCampaign', updateCampaign)
      commit('cleanAppSidebar')
      emitter.$emit('Notification', { variant: 'success', title: 'İşlem Başarılı' })
    } catch (error) {
      console.log(error)
    }
  },
  getWebCampaigns: async ({ commit }) => {
    try {
      const { campaigns } = await graphqlRequest('/web-campaigns', { query: '{ campaigns{ _id name status startDate endDate } }' }, { loading: 'table' })
      commit('setWebCampaigns', campaigns)
    } catch (error) {
      console.log(error)
    }
  },
  getCampaignDetails: async ({ commit }, id) => {
    try {
      const { campaign } = await graphqlRequest(
        '/web-campaigns',
        {
          query: `
        query campaign($id:String!){
          campaign(id:$id){
            _id name status startDate endDate percent riskStates sectors osgb osb employeeCount{greaterThan lessThan}
            areas{provinces counties}
          }
        }
      `,
          variables: { id },
        },
        { loading: 'table' },
      )
      commit('setAppSidebarOptions', {
        show: true,
        title: 'Kampanya Güncelle',
        component: campaignSidebar,
        item: campaign,
      })
    } catch (error) {
      emitter.$emit('Notification', { variant: 'danger', title: 'Hata Oluştu' })
      console.log(error)
    }
  },
  updateCampaignStatus: async ({ commit }, items) => {
    try {
      const { updateCampaign } = await graphqlRequest(
        '/web-campaigns',
        {
          query: `
      mutation updateCampaign($_id:String!, $status:Boolean){
        updateCampaign(_id:$_id, status:$status){ _id name status startDate endDate }
      }
      `,
          variables: items,
        },
        { loading: 'table' },
      )
      commit('updateWebCampaign', updateCampaign)
    } catch (error) {
      emitter.$emit('Notification', { variant: 'danger', title: 'Hata Oluştu' })
      console.log(error)
    }
  },
  deleteWebCampaign: async ({ commit }, id) => {
    try {
      const { deleteCampaign } = await graphqlRequest(
        '/web-campaigns',
        {
          query: `
      mutation deleteCampaign($id:String!){
        deleteCampaign(id:$id){ _id }
      }
      `,
          variables: { id },
        },
        { loading: 'table' },
      )
      commit('deleteWebCampaign', deleteCampaign._id)
    } catch (error) {
      emitter.$emit('Notification', { variant: 'danger', title: 'Hata Oluştu' })
      console.log(error)
    }
  },
}
