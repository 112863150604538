const sortVisits = (data) => data.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

export default {
  setVisits: (state, visits) => (state.visits = visits),
  setVisit: (state, visit) => (state.visit = visit),
  setNewManyPlanedVisits: (state, visits) => {
    state.planedVisits.data = sortVisits([...visits, ...state.planedVisits.data]);
    state.planedVisits.total += visits.length;
  },
  setNewManyVisits: (state, visits) => {
    state.visits.data = sortVisits([...visits, ...state.visits.data]);
    state.visits.total += visits.length;
  },
  setPlanedVisits: (state, visits) => (state.planedVisits = visits),
  setNotCompletedVisits: (state, visits) => (state.notCompletedVisits = visits),
  setCompletedVisits: (state, visits) => (state.completedVisits = visits),
  setVisitCompleted: (state, { id, status }) => {
    /*  const planedVisit = state.planedVisits.findIndex((item) => item._id === id);
    if (planedVisit !== -1) {
      state.planedVisits[planedVisit].status = status;
      state.completedVisits.unshift(state.planedVisits[planedVisit]);
      state.completedVisits = sortVisits(state.completedVisits);
      state.planedVisits = state.planedVisits.filter((item) => item._id !== id);
    }
    const visitIndex = state.visits.findIndex((item) => item._id === id);
    if (visitIndex !== -1) state.visits[visitIndex].status = status; */
    state.visit.status = status;
  },
  updateVisitStartDate: (state, { id, startDate }) => {
    /*  const planedVisit = state.planedVisits.findIndex((item) => item._id === id);
    if (planedVisit !== -1) state.planedVisits[planedVisit].startDate = startDate;
    const visitIndex = state.visits.findIndex((item) => item._id === id);
    if (visitIndex !== -1) state.visits[visitIndex].startDate = startDate;
    
    state.planedVisits = sortVisits(state.planedVisits);
    state.visits = sortVisits(state.visits); */

    state.visit.startDate = startDate;
  },
  updateVisitToNotCompleted: (state, { id, data }) => {
    /*  const planedVisit = state.planedVisits.findIndex((item) => item._id === id);
    if (planedVisit !== -1) {
      state.planedVisits[planedVisit].status = data.status;
      state.planedVisits[planedVisit].notCompletedReason = data.notCompletedReason;
      state.notCompletedVisits.unshift(state.planedVisits[planedVisit]);
      state.notCompletedVisits = sortVisits(state.notCompletedVisits);
      state.planedVisits = state.planedVisits.filter((item) => item._id !== id);
    }
    const visitIndex = state.visits.findIndex((item) => item._id === id);
    if (visitIndex !== -1) {
      state.visits[visitIndex].status = data.status;
      state.visits[visitIndex].notCompletedReason = data.notCompletedReason;
    } */
    state.visit.status = data?.status;
    state.visit.notCompletedReason = data?.notCompletedReason;
  },
};
