<template>
  <div>
    <b-dropdown
      text="Primary"
      variant="link"
      toggle-class="text-decoration-none p-0"
      :offset="offset"
      :menu-class="block ? 'w-100' : ''"
      :right="position === 'right'"
      no-caret
      :boundary="boundary"
      :block="block"
    >
      <template #button-content>
        <slot v-if="$scopedSlots['button-content']" name="button-content" />
        <div v-else>
          <b-button :variant="variant" :class="'p-50 btn-icon rounded-circle'">
            <feather-icon icon="ChevronDownIcon" size="15" />
          </b-button>
        </div>
      </template>
      <slot />
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "gradient-primary",
    },
    offset: {
      type: String,
      default: "55",
    },
    position: {
      type: String,
      default: "right",
    },
    boundary: {
      type: String,
      default: "viewport",
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
